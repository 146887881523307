/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { calculateStepPricing } from '@/utils/cart.utils'
import { ActionContext } from 'vuex'
import PromotionCodePrivider from '@/resources/promotion-code.provider'
import OrderProvider from '@/resources/order.provider'

const PromotionCodeService = new PromotionCodePrivider()
const OrderService = new OrderProvider()
const state = {
  items: [],
  promotionCode: null,
  discount: 0,
  payment: {
    id: null
  },
  billResult: null,
  cashAmount: 0,
  bill: {
    member: { tel: '' }
  }
}

const actions = {
  async validateCode (store: ActionContext<typeof state, any>, code: string): Promise<any> {
    try {
      const items = calculateStepPricing(store.state.items)
      const { data } = await PromotionCodeService.validatePromotionCode({
        code,
        member: { tel: store.state.bill.member.tel },
        channel: 'web',
        items: items.map((item: any) => ({ ...item, skuId: item.id, brand: { id: 1, name: 'gentlewoman' } }))
      }, store.rootState.Store.storeInfo.id)
      if (data.success) {
        store.commit('SET_DISCOUNT', data.discount)
        store.commit('SET_PROMOTIONCODE', data.promotionCode)
      }
      if (data.success) {
        return { success: true, message: 'This code can be use.' }
      }
      return { success: data.success, message: data.error.message }
    } catch (err: any) {
      return { message: err.message, success: false }
    }
  },
  async createOrder (store: ActionContext<typeof state, any>): Promise<any> {
    try {
      const skus = calculateStepPricing(store.state.items)
      const { discount, promotionCode, bill, payment } = store.state
      const { warehouse } = store.rootState.Store.selectBranch
      const expire = new Date()
      const paymentChannel = store.rootState.Store.paymentChannels.find((pm: any) => pm.id === payment.id)
      const payload = {
        ...bill,
        skus,
        discount,
        promotionCode,
        warehouse,
        payments: [{
          debtAmount: store.getters.net,
          payOrder: 1,
          sumDebtAmount: store.getters.net,
          payAmount: store.getters.net,
          channel: paymentChannel.name,
          channelId: paymentChannel.id,
          channelType: paymentChannel.type,
          deadlineDate: expire,
          note: '',
          paidDate: expire
        }]
      }
      const { data } = await OrderService.completeOrderClickCollect(payload)
      store.commit('SET_BILL_RESULT', data)
      return {
        success: true,
        message: 'success'
      }
    } catch (err: any) {
      return {
        success: false,
        message: err?.message || ''
      }
    }
  },
  setItems (store: ActionContext<typeof state, any>, payload: any[]): void {
    store.commit('SET_ITEMS', payload)
  },
  clearDiscount (store: ActionContext<typeof state, any>) {
    store.commit('SET_DISCOUNT', 0)
    store.commit('SET_PROMOTIONCODE', null)
  },
  setPromotionCode (store: ActionContext<typeof state, any>, payload: any): void {
    store.commit('SET_PROMOTIONCODE', payload)
  },
  setDiscount (store: ActionContext<typeof state, any>, payload: any): void {
    store.commit('SET_DISCOUNT', payload)
  },
  setPayment (store: ActionContext<typeof state, any>, payload: any): void {
    store.commit('SET_PAYMENT', payload)
  },
  setCashAmount (store: ActionContext<typeof state, any>, payload: any): void {
    store.commit('SET_CASH_AMOUNT', payload)
  },
  setBill (store: ActionContext<typeof state, any>, payload: any): void {
    store.commit('SET_BILL', payload)
  }
}

const mutations = {
  SET_ITEMS (state: any, payload: any): void {
    state.items = payload
  },
  SET_BILL_RESULT (state: any, payload: any): void {
    state.billResult = payload
  },
  SET_PROMOTIONCODE (state: any, payload: any): void {
    state.promotionCode = payload
  },
  SET_DISCOUNT (state: any, payload: any): void {
    state.discount = payload
  },
  SET_PAYMENT (state: any, payload: any): void {
    state.payment = payload
  },
  SET_CASH_AMOUNT (state: any, payload: any): void {
    state.cashAmount = payload
  },
  SET_BILL (state: any, payload: any): void {
    state.bill = payload
  }
}

const getters = {
  payment: (state: any) => state.payment,
  calculatedItems: (state: any): any[] => calculateStepPricing(state.items),
  tel: (state: any): any => state.bill.member.tel,
  discount: (state: any) => state.discount,
  fullPrice: (
    state: any
    ) => getters.calculatedItems(state).reduce((sum: number, item: any) => sum + item.fullPrice, 0),
  totalPrice: (
    state: any
  ) => getters.calculatedItems(state).reduce((sum: number, item: any) => sum + item.price, 0),
  net: (
    state: any
  ) => {
    const total = getters.totalPrice(state) - state.discount
    return total < 0 ? 0 : total
  },
  promotionCode: (state: any) => state.promotionCode,
  bill: (state: any) => state.billResult
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
