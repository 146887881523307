


















































































import { Component, Vue } from 'vue-property-decorator'
import SelectStore from '@/components/select-branch.vue'
import { mapGetters, mapState } from 'vuex'
import ProductProvider from '@/resources/product.provider'
import ProductList from '@/components/product-list.vue'
import { mapProduct } from '@/utils/product.utils'

const ProductService = new ProductProvider()

@Component({
  components: {
    ProductList,
    SelectStore
  },
  computed: {
    ...mapState({
      selectBranch: (state: any) => state.Store.selectBranch,
      storeInfo: (state: any) => state.Store.storeInfo
    }),
    ...mapGetters({
      cartAmount: 'Cart/cartAmount'
    })
  }
})
export default class ProductListPage extends Vue {
  search = ''

  loading = false

  storeInfo!: any

  cartAmount!: any

  activeModal = false

  selectBranch!: any

  products: any[] = []

  clearCart!: any

  mounted (): void {
    if (!this.selectBranch || this.selectBranch === null) {
      this.activeModal = true
    }
    if (this.searchQuery !== '') {
      this.search = `${this.searchQuery}`
      this.searchProduct()
    }
  }

  get storeName (): string {
    return this.storeInfo?.name || 'STORE NOT FOUND'
  }

  get warehouseName (): string {
    if (!this.selectBranch || this.selectBranch === null) {
      return ''
    }
    return this.selectBranch.warehouse.name
  }

  get searchQuery (): string | (string | null)[] {
    return this.$route.query.search || ''
  }

  async searchProduct (): Promise<void> {
    this.loading = true
    const { data: { results } } = await ProductService.getProduct({ search: this.search })

      this.products = mapProduct(results, this.selectBranch, this.search)
    if (this.products.length === 0) {
      this.$buefy.toast.open({ message: 'Product not found', type: 'is-danger' })
    }
    this.loading = false
  }

  closeModal (): void {
    this.activeModal = false
  }
}
