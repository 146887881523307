/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ActionContext } from 'vuex'

const state = {
  dailySummary: {
    date: null,
    payments: []
  }
}

const actions = {
  setDailySummary (store: ActionContext<typeof state, any>, payload: any): void {
    store.commit('SET_DAILY_SUMMARY', payload)
  }
}

const mutations = {
  SET_DAILY_SUMMARY (state: any, payload: any) {
    state.dailySummary = payload
  }
}

const getters = {
  dailySummary: (state: any) => state.dailySummary
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
