




































/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types  */
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { ExportToCsv } from 'export-to-csv'
import SummaryDailyProvider from '@/resources/summary-daily.provider'

const SummaryDailyService = new SummaryDailyProvider()

dayjs.extend(utc)
@Component({
  computed: {
    ...mapState({
      selectBranch: (state: any) => state.Store.selectBranch
    })
  }
})
export default class DailyOrder extends Vue {
  selectBranch!: any

  orders: any[] = []

  date = new Date(dayjs().format())

  loading = false

  download (url: string, fileName = 'ORDER') {
      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.download = fileName
      downloadLink.click()
    }

  async submitBtn (): Promise<void> {
    this.loading = true
    await this.getOrder()
    this.loading = false
  }

  async getOrder (): Promise<void> {
    const { data } = await SummaryDailyService.getExportProduct(this.formatStartEndDate(this.date), this.selectBranch.warehouse.id)

    this.download(data)
  }

  formatStartEndDate (_date: Date): any {
    const endDate = dayjs(_date).add(1, 'day').set('hour', 0).set('minute', 0)
                    .set('second', 0)
                    .set('milliseconds', 0)
                    .utc()
                    .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
    const startDate = dayjs(_date).set('hour', 0).set('minute', 0)
                      .set('milliseconds', 0)
                      .set('second', 0)
                      .utc()
                      .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
    return {
      startDate,
      endDate
    }
  }

  formatDateTimeFromUtc (date: Date): string {
    if (date) {
      const _date = new Date(date)
      return dayjs(_date).format('YYYY-MM-DD HH:mm:ss')
    }
    return ''
  }

  async exportCSV (): Promise<void> {
    const options = {
      filename: `DailyOrder-${dayjs(this.date).format('DD-MM-YYYY')}`,
      showLabels: true,
      useKeysAsHeaders: true
    }

    const csvExporter = new ExportToCsv(options)
    csvExporter.generateCsv(this.setFormatCSV(this.orders || []))
  }

  setFormatCSV (orders: any): any {
    if (!orders || !orders.length) {
      return [{
        'Order Id': '',
        'Created Time': '',
        'Name': '',
        'Code': '',
        'Price': '',
        'Discount': '',
        'QTY': '',
        'Net': '',
        'Payment': '',
        'Nationality': '',
        'Gender': '',
        'Branch': '',
        'Reference': ''
      }]
    }

    return orders.map((order: any) => ({
      'Order Id': order?.orderId || '',
      'Created Time': this.formatDateTimeFromUtc(order?.createdTime || ''),
      'Name': order?.name || '',
      'Code': order?.code || '',
      'Price': order?.fullPrice || 0,
      'Discount': order?.discount || 0,
      'QTY': order?.amount || 0,
      'Net': order?.net || 0,
      'Payment': order?.paymentChannel || '',
      'Nationality': order?.nationality || '',
      'Gender': order?.gender || 'N/A',
      'Branch': order?.warehouse || '',
      'Reference': ''
    }))
  }
}
