/* eslint-disable @typescript-eslint/no-explicit-any */
import { getAuthToken } from '@/utils/auth.utils'
import HttpRequest from './http-request'

interface OrderQueryInterface {
  page: number
  limit: number
  channel: string
  search: string
  currentState: string
  sortBy?: string
  sortOrder?: string
}
class OrderProvider extends HttpRequest {
  createOrder (payload: any): Promise<any> {
    this.setHeader(getAuthToken())
    return this.create('order/pos', payload)
  }

  // http://localhost:7777/orders/{orderId}
  getOrderById (orderId: string): Promise<any> {
    this.setHeader(getAuthToken())
    return this.get(`orders/${orderId}`)
  }

  getOrders (query: OrderQueryInterface): Promise<any> {
    this.setHeader(getAuthToken())
    return this.get('orders/list/detail', query)
  }

  completeOrderClickCollect (payload: any): Promise<any> {
    this.setHeader(getAuthToken())
    return this.create('order/click-collect/complete', payload)
  }

  completeExchangeOrder (payload: any): Promise<any> {
    this.setHeader(getAuthToken())
    return this.create('orders/exchange', payload)
  }

  fetchBillingByOrderId (orderId: string): Promise<any> {
    this.setHeader(getAuthToken())
    return this.get(`order/pos/${orderId}/bill`)
  }
}

export default OrderProvider
