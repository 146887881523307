/* eslint-disable @typescript-eslint/no-explicit-any */
import HttpRequest from './http-request'

class AuthProvider extends HttpRequest {
  login (token: string): Promise<any> {
    return this.create('/users/login', {
      tokenReference: token
    })
  }
}

export default AuthProvider
