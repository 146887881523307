/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ActionContext } from 'vuex'
import OrderWarehouseProvider from '@/resources/order-warehouse.provider'
import dayjs from 'dayjs'
import { getAuthDecode } from '@/utils/auth.utils'

const OrderService = new OrderWarehouseProvider()

const state = {
  order: {},
  skus: [],
  preReceipt: {
    storeDisplayName: '',
    branchPos: {
      name: '',
      taxId: '',
      posId: ''
    },
    cashier: {
      firstName: '',
      lastName: ''
    },
    displayTime: dayjs(),
    billId: '',
    items: [],
    discount: 0,
    price: 0,
    calPriceBeforeVat: 0,
    payment: {
      name: '',
      amount: 0,
      type: '',
      change: ''
    }
  }
}

const actions = {
  async findOrder (store: ActionContext<typeof state, any>, search: string): Promise<any> {
    try {
      const { data } = await OrderService.getOrderById(search)

      if (data.currentState === 'ready_to_ship' || data.currentState === 'completed') {
        store.commit('SET_ORDER', data)

        const mappedSkus: Array<any> = []

        data.skus.forEach((sku: any) => {
          for (let i = 0; i < sku.amount; i++) {
            mappedSkus.push({ ...sku, isValid: false })
          }
        })

        store.commit('SET_SKUS', mappedSkus)
        return data
      }
    } catch (err) {
      console.error('findOrder', err)
    }
    return null
  },
  setOrder: (store: ActionContext<typeof state, any>, data: any) => {
    store.commit('SET_ORDER', data)
  },
  setSkus: (store: ActionContext<typeof state, any>, data: any) => {
    store.commit('SET_SKUS', data)
  },
  clearOrder: (store: ActionContext<typeof state, any>) => {
    store.commit('SET_ORDER', {})
    store.commit('SET_SKUS', [])
  },
  addTrackings: (store: ActionContext<typeof state, any>, data: any) => OrderService.addTrackings({
    orderId: data.orderId, trackingNo: data.trackingNo
  }),
  completeOrder: (store: ActionContext<typeof state, any>, data: any) => OrderService.completeOrder(data),
  setPreReceipt: (store: ActionContext<typeof state, any>, data: any) => {
    store.commit('SET_SKUS', data)
  },
  async previewReceipt (store: ActionContext<typeof state, any>, data: any): Promise<any> {
    /* eslint-disable prefer-destructuring */
    /* eslint-disable @typescript-eslint/explicit-module-boundary-types */
    try {
      const change = 0
      let paymentName = ''
      let discount = 0
      let amount = 0
      let type = ''
      type = 'cash'
      paymentName = store.rootState.Cart.payment.name
      amount = store.rootState.Cart.cashAmount
      discount = store.rootState.Cart.discount
      const user = getAuthDecode()
      const nameArr = user.name.split(' ')
      const posId = store.rootState.Store?.selectPos?.posId || `POS NO. ${store.rootState.Store.selectBranch.warehouse.id}`
      const payload = {
        billId: '-',
        branchPos: {
          branchId: store.rootState.Store.selectBranch.warehouse.id,
          name: store.rootState.Store.selectBranch.warehouse.name,
          posId,
          branch: store.rootState.Store.branchAddress.branch,
          taxId: store.rootState.Store.branchAddress.taxId ? `TAX#${store.rootState.Store.branchAddress.taxId}` : 'TAX#0-1055-61034-56-1',
          unitId: store.rootState.Store.selectBranch.warehouse.id,
          code: store.rootState.Store.selectBranch.warehouse.code
        },
        payment: {
          name: paymentName,
          type,
          amount,
          change: change < 0 ? 0 : change
        },
        items: store.rootGetters['Cart/groupedItems'].map((sku: any) => ({
          amount: sku.amount,
          price: sku.price,
          name: sku.name,
          option1: sku.size,
          option2: sku.color,
          variant1: 'Size',
          variant2: 'Color',
          categories: sku.categories,
          tags: sku.tags,
          code: sku.code,
          productId: sku.productId,
          fullPrice: sku.fullPrice,
          id: sku.id,
          image: sku.images[0] || ''
        })),
        discount,
        print: { message: '', status: data.status, ip: data.ip },
        price: store.rootGetters['Cart/net'],
        cashier: {
          id: user.userId,
          firstName: nameArr[0],
          lastName: nameArr[1] ? nameArr[1] : ''
        },
        displayTime: dayjs(),
        storeDisplayName: store.rootState.Store.storeInfo.name,
        storeLogo: store.rootState.Store.storeSetting.logoUrl || 'https://new-gentlewoman.s3.ap-southeast-1.amazonaws.com/store-logo/logo-shop.png'
      }
      return payload
    } catch (err) {
      console.log(err)
      return null
    }
  },
  async previewExchangeReceipt (store: ActionContext<typeof state, any>, data: any): Promise<any> {
    /* eslint-disable prefer-destructuring */
    /* eslint-disable @typescript-eslint/explicit-module-boundary-types */
    try {
      const change = 0
      let paymentName = ''
      let discount = 0
      let amount = 0
      let type = ''
      type = 'cash'
      paymentName = store.rootState.ExchangeOrder.payment.name
      amount = store.rootState.ExchangeOrder.cashAmount
      discount = store.rootState.ExchangeOrder.discount
      const user = getAuthDecode()
      const nameArr = user.name.split(' ')
      const posId = store.rootState.Store?.selectPos?.posId || `POS NO. ${store.rootState.Store.selectBranch.warehouse.id}`
      const payload = {
        billId: '-',
        branchPos: {
          branchId: store.rootState.Store.selectBranch.warehouse.id,
          name: store.rootState.Store.selectBranch.warehouse.name,
          posId,
          branch: store.rootState.Store.branchAddress.branch,
          taxId: store.rootState.Store.branchAddress.taxId ? `TAX#${store.rootState.Store.branchAddress.taxId}` : 'TAX#0-1055-61034-56-1',
          unitId: store.rootState.Store.selectBranch.warehouse.id,
          code: store.rootState.Store.selectBranch.warehouse.code
        },
        payment: {
          name: paymentName,
          type,
          amount,
          change: change < 0 ? 0 : change
        },
        items: store.rootGetters['ExchangeOrder/groupedItems'].map((sku: any) => ({
          amount: sku.amount,
          price: sku.price,
          name: sku.name,
          option1: sku.size,
          option2: sku.color,
          variant1: 'Size',
          variant2: 'Color',
          categories: sku.categories,
          tags: sku.tags,
          code: sku.code,
          productId: sku.productId,
          fullPrice: sku.fullPrice,
          id: sku.id,
          image: sku.images[0] || ''
        })),
        discount,
        print: { message: '', status: data.status, ip: data.ip },
        price: store.rootGetters['ExchangeOrder/net'],
        cashier: {
          id: user.userId,
          firstName: nameArr[0],
          lastName: nameArr[1] ? nameArr[1] : ''
        },
        displayTime: dayjs(),
        storeDisplayName: store.rootState.Store.storeInfo.name,
        storeLogo: store.rootState.Store.storeSetting.logoUrl || 'https://new-gentlewoman.s3.ap-southeast-1.amazonaws.com/store-logo/logo-shop.png'
      }
      return payload
    } catch (err) {
      return null
    }
  }
}

const mutations = {
  SET_ORDER (state: any, data: any): void {
    state.order = data
  },
  SET_SKUS (state: any, data: Array<any>): void {
    state.skus = data
  },
  SET_PRE_RECEIPT (state: any, data: Array<any>): void {
    state.preReceipt = data
  }
}

const getters = {
  getOrder: (state: any) => state.order,
  getSkus: (state: any) => state.skus,
  preReceipt: (state: any) => state.preReceipt
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
