import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import Store from './modules/store-module.store'
import Product from './modules/product-module.store'
import Cart from './modules/cart-module.store'
import Order from './modules/order-module.store'
import Print from './modules/print-module.store'
import ClickCollect from './modules/click-collect.store'
import ExchangeOrder from './modules/exchange-order.store'
import DailySale from './modules/summary-daily-sale.store'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['Store', 'Cart', 'Order', 'ClickCollect', 'ExchangeOrder']
})

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Store,
    Product,
    Cart,
    Order,
    Print,
    ClickCollect,
    ExchangeOrder,
    DailySale
  },
  plugins: [
    vuexLocal.plugin
  ]
})
