/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const state = {
  products: []
}

const actions = {
}

const mutations = {
}

const getters = {
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
