


















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ProductList extends Vue {
  @Prop()
  readonly stocks!: any

  @Prop()
  readonly product!: any

  columns = [
    {
      field: 'warehouse.name',
      label: 'สาขา',
      centered: true
    },
    {
      field: 'onHandQty',
      label: 'คงเหลือ',
      numeric: true
    },
    {
      field: 'onReservedQty',
      label: 'จอง',
      numeric: true
    }
  ]

  closePopup (): void {
    this.$emit('onClose')
  }
}
