/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { getAuthToken } from '@/utils/auth.utils'
import HttpRequest from './http-request'

class DailyBriefProvider extends HttpRequest {
  getDailyBrives (): Promise<any> {
    this.setHeader(getAuthToken())
    return this.get('daily-brief')
  }
}

export default DailyBriefProvider
