




























































































import { Component, Vue } from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'
import { VueTelInput } from 'vue-tel-input'
import ProductList from './components/product-list.vue'

@Component({
  components: { ProductList, VueTelInput },
  computed: {
    ...mapState({
      selectBranch: (state: any) => state.Store.selectBranch,
      storeInfo: (state: any) => state.Store.storeInfo
    }),
    ...mapGetters({
      calculatedItems: 'ClickCollect/calculatedItems',
      tel: 'ClickCollect/tel',
      net: 'ClickCollect/net',
      discount: 'ClickCollect/discount',
      fullPrice: 'ClickCollect/fullPrice',
      totalPrice: 'ClickCollect/totalPrice',
      promotionCode: 'ClickCollect/promotionCode'
    })
  },
  methods: {
    ...mapActions({
      validateCode: 'ClickCollect/validateCode',
      setPromotionCode: 'ClickCollect/setPromotionCode',
      clearDiscount: 'ClickCollect/clearDiscount',
      getStepPricing: 'Cart/getStepPricing'
    })
  }
})
export default class Home extends Vue {
  selectBranch!: any

  loading = false

  tel!: any

  storeInfo!: any

  validateCode!: any

  getStepPricing!: any

  code = ''

  promotionCode!: any

  calculatedItems!: any

  net!: any

  discount!: any

  fullPrice!: any

  totalPrice!: any

  showFullPriceFormat!: any

  clearDiscount!: any

  get isDisableApplyBtn (): boolean {
    if (this.tel === null) {
      return true
    }
    if (this.promotionCode != null) {
      return true
    }
    return false
  }

  async mounted (): Promise<void> {
    await this.getStepPricing()
    this.code = this.promotionCode.code
  }

  async validDateCodeHandle (): Promise<void> {
    this.loading = true
    const result = await this.validateCode(this.code.toUpperCase())
    if (result.success) {
      this.$buefy.dialog.alert('This code can be use.')
    } else {
      this.$buefy.dialog.alert(`<span style="color: red">${result.message}</span>`)
    }
    this.loading = false
  }

  get warehouseName (): string {
    if (!this.selectBranch || this.selectBranch === null) {
      return ''
    }
    return this.selectBranch.warehouse.name
  }
}
