import { getAuthToken } from '@/utils/auth.utils'
import HttpRequest from './http-request'

type warehouseField = {
  id: number;
  name: string;
  code: string;
}

type CreateBehaviorEventInterface = {
  orderId?: string;
  event: string;
  warehouse: warehouseField;
}

class TrackingBehaviorProvider extends HttpRequest {
  createEvent (payload: CreateBehaviorEventInterface): Promise<any> {
    this.setHeader(getAuthToken())
    return this.create('/tracking-behavior', payload)
  }
}

export default TrackingBehaviorProvider
