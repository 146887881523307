/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { getAuthToken } from '@/utils/auth.utils'
import HttpRequest from './http-request'

class PerformanceReportProvider extends HttpRequest {
  getPerformanceReport (query: any): Promise<any> {
    this.setHeader(getAuthToken())
    return this.get('performance-report/pos', query)
  }
}

export default PerformanceReportProvider
