/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { getAuthToken } from '@/utils/auth.utils'
import HttpRequest from './http-request'

export default class ProductProvider extends HttpRequest {
  getProduct (query: any): Promise<any> {
    this.setHeader(getAuthToken())
    return this.get('/products/pos/list', query)
  }

  getProductById (id: string): Promise<any> {
    this.setHeader(getAuthToken())
    return this.get(`/products/${id}`)
  }

  getProductManyByCodes (codes: string[]): Promise<any> {
    this.setHeader(getAuthToken())
    return this.create('/products/many-by-code', { codes })
  }
}
