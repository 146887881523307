/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line import/prefer-default-export
export const mapProduct = (products: Array<any>, branch: any, search = ''): any => {
  let results: Array<any> = []
  const { warehouse } = branch
  for (const product of products) {
    for (const sku of product.skus) {
      const stock = sku.stock.find((st: any) => st.warehouse.id === warehouse.id)
      const allStock = sku.stock.filter((it: any) => it.warehouse.id !== 0)
      const initStock = stock || {
        onHandQty: 0,
        onReservedQty: 0,
        id: null,
        warehouse
      }
      const payload = {
        id: sku.id,
        code: sku.code,
        codeTemp: sku.codeTemp,
        productId: product.id,
        name: product.name,
        warehouse,
        stock: initStock,
        allStock,
        color: sku.color.name,
        description: product.description,
        isOnWeb: product.isOnWeb,
        size: sku.size.name,
        option: sku.option,
        fullPrice: sku.price,
        images: product.photoUrls,
        categories: product.categories,
        groupCategories: product.groupCategories || [],
        gwCollection: product?.gwCollection?.id || null,
        tags: product.tags,
        brand: product.brand,
        variant: product.variant,
        price: sku.price,
        amount: 1
      }
      results.push(payload)
    }
  }

  if (products.length === 1) {
    const index = results.findIndex((re) => re.code === search)
    if (index !== -1) {
      results = [results[index]]
    }
  }

  return results
}
