



























































  import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { mapActions, mapState } from 'vuex'

@Component({
  computed: {
    ...mapState({
      branchs: (state: any) => state.Store.branchs,
      branchPos: (state: any) => state.Store.branchPos,
      previousSelectPos: (state: any) => state.Store.selectPos
    })
  },
  methods: {
    ...mapActions({
      setSelectBranch: 'Store/setSelectBranch',
      setSelectPos: 'Store/setSelectPos',
      setBranchPos: 'Store/setBranchPos'
    })
  }
})
export default class SelectBranch extends Vue {
  setSelectBranch!: any

  selectBranch = null

  branchs!: any

  setBranchPos!: any

  setSelectPos!: any

  selectPos = null

  previousSelectPos!: any

  branchPos!: any

  $parent!: any

  get isDisableBtn (): boolean {
    return this.selectBranch === null
  }

  // eslint-disable-next-line class-methods-use-this
  get isMobile (): boolean {
    return window.innerWidth <= 768
  }

  async fetchBranchPos (event: any): Promise<void> {
    await this.setBranchPos(event.warehouse.id)
    if (!this.selectPos && this.previousSelectPos.posId && this.branchPos) {
      const previousPosIsExist = this.branchPos.find((pos: any) => pos.posId === this.previousSelectPos.posId)
      this.selectPos = previousPosIsExist
    } else {
      this.selectPos = null
    }
  }

  isSelectBranch (): void {
    this.setSelectBranch(this.selectBranch)
    this.setSelectPos(this.selectPos || {
      id: '',
      posId: '',
      serialNumber: '',
      assetId: ''
    })
    this.$parent.$emit('close')
  }
}
