/* eslint-disable @typescript-eslint/no-explicit-any */
import { getAuthToken } from '@/utils/auth.utils'
import HttpRequest from './http-request'

class OrderWarehouseProvider extends HttpRequest {
  // http://localhost:7777/orders/{orderId}
  getOrderById (orderId: string): Promise<any> {
    this.setHeader(getAuthToken())
    return this.get(`orders/warehouse/${orderId}`)
  }

  addTrackings (payload: any): Promise<any> {
    this.setHeader(getAuthToken())
    return this.patch(`orders/${payload.orderId}/tracking`, { refCode: payload.trackingNo })
  }

  completeOrder (orderId: string): Promise<any> {
    this.setHeader(getAuthToken())
    return this.patch(`orders/${orderId}/complete`, {})
  }
}

export default OrderWarehouseProvider
