






































import { Component, Vue } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'
import MemberProvider from '@/resources/member.provider'

const memberService = new MemberProvider()

@Component({
  methods: {
    ...mapActions({
      setPayment: 'Cart/setPayment',
      ccSetPayment: 'ClickCollect/setPayment',
      exoSetPayment: 'ExchangeOrder/setPayment',
      initPayment: 'Store/initPayment'
    })
  },
  computed: {
    ...mapGetters({
      net: 'Cart/net',
      nationality: 'Cart/nationality',
      gender: 'Cart/gender',
      tel: 'Cart/tel',
      ccNet: 'ClickCollect/net',
      exoNet: 'ExchangeOrder/payMore',
      paymentChannels: 'Store/paymentChannels'
    })
  }
})
export default class Payment extends Vue {
  readonly setPayment!: any

  readonly ccSetPayment!: any

  readonly exoSetPayment!: any

  public readonly net!: any

  paymentChannels!: any[]

  initPayment!: any

  showPriceFormat!: any

  public readonly nationality!: any

  public readonly gender!: any

  public readonly tel!: any

  public readonly ccNet!: any

  public readonly exoNet!: any

  public memberData: any = null

  get realNet (): any {
    if (this.type === 'clickcollect') {
      return this.ccNet
    }
    if (this.type === 'exchange-order') {
      return this.exoNet
    }
    return this.net
  }

  get type (): any {
    return this.$route.query?.type || 'receipt'
  }

  async mounted (): Promise<void> {
    await this.initPayment()
    if (this.type === 'receipt') {
      const { data } = await memberService.getMemberByTel(this.tel)
      if (data) {
        this.memberData = data
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  checkout (payment: any): void {
    if (payment.type === 'cash') {
      this.$router.push({ name: 'CheckoutByCash', query: { type: this.type } })
    } else {
      this.$router.push({ name: 'CheckoutByCard', query: { type: this.type } })
    }

    switch (this.type) {
      case 'exchange-order':
        this.exoSetPayment(payment)
        break

      case 'clickcollect':
        this.ccSetPayment(payment)
        break

      case 'receipt':
        this.setPayment(payment)
        break

      default: break
    }
  }
}
