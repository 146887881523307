












































































import { Component, Prop, Vue } from 'vue-property-decorator'
import QrReader from '@/components/qr-code-reader.vue'
import ProductProvider from '@/resources/product.provider'
import { mapProduct } from '@/utils/product.utils'
import { mapActions, mapState } from 'vuex'
import ProductList from './product-list.vue'

const ProductService = new ProductProvider()

@Component({
  components: {
    ProductList,
    QrReader
  },
  computed: {
    ...mapState({
      selectBranch: (state: any) => state.Store.selectBranch
    })
  },
  methods: {
    ...mapActions({
      addToCart: 'ExchangeOrder/addToCart'
    })
  }
})
export default class ModalProductAddtoCart extends Vue {
  @Prop()
  showModal!: boolean;

  addToCart!: any;

  showQr = false;

  products: any[] = [];

  selectBranch!: any;

  loading = false;

  search = '';

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  addItem (item: any): void {
    this.addToCart({ ...item, operation: 'new_item' })
    this.closeModal()
  }

  decodeHandle (rawText: string): void {
    const textArr = rawText.split('/')
    this.search = textArr[textArr.length - 1]
    this.showQr = false
    this.getProducts()
  }

  closeModal (): void {
    this.$emit('onCloseModal')
  }

  async getProducts (): Promise<void> {
    try {
      this.loading = true
      const {
        data: { results }
      } = await ProductService.getProduct({ search: this.search })
      this.products = mapProduct(results, this.selectBranch, this.search)
    } catch (err) {
      console.error('getProducts', err)
    } finally {
      this.loading = false
    }
  }
}
