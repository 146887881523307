






































































































/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types  */
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Component, Vue } from 'vue-property-decorator'
import { mapGetters, mapState, mapActions } from 'vuex'

dayjs.extend(utc)
@Component({
  methods: {
    ...mapActions({
      setDailySummary: 'DailySale/setDailySummary'
    })
  },
  computed: {
    ...mapState({
      selectBranch: (state: any) => state.Store.selectBranch
    }),
    ...mapGetters({
      paymentChannel: 'Store/paymentChannels'
    })
  }
})
export default class PaymentSummary extends Vue {
  selectBranch!: any

  paymentChannel!: any[]

  showPriceFormat!: any

  readonly setDailySummary!: any

  payments: any[] = []

  date = new Date(dayjs().format())

  mounted (): void {
    this.initSummaryPayment()
  }

  initSummaryPayment (): void {
    this.payments = this.paymentChannel.map((payment: any) => ({
      ...payment,
      inputAmount: payment?.inputAmount || 0
    }))
  }

  get totalPayment (): number {
    return this.payments.reduce((total, acc) => total + acc.inputAmount, 0)
  }

  formatDate (date: Date): string {
    return dayjs(date).format('DD-MM-YYYY')
  }

  submitCloseAccount (_date: Date, payments: any): void {
    this.setDailySummary({
      date: _date,
      payments
    })
    this.$router.push({ name: 'PaymentSummary' })
  }
}
