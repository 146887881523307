









import { Component, Vue } from 'vue-property-decorator'
import AuthProvider from '@/resources/auth.provider'
import { setAuthUser, clearAuth } from '@/utils/auth.utils'
import { mapActions } from 'vuex'

const AuthService = new AuthProvider()

@Component({
  methods: {
    ...mapActions({
      initStore: 'Store/initStore',
      setSelectBranch: 'Store/setSelectBranch'
    })
  }
})

export default class Login extends Vue {
  initStore!: () => any

  setSelectBranch!: any

  created (): void {
    clearAuth()
    this.checkPage()
  }

  checkPage (): void {
    if (this.$route.name === 'Logout') {
      this.setSelectBranch(null)
      setTimeout(() => {
        const uri = `${window.location.origin}${this.$route.path}`
        window.location.replace(`${process.env.VUE_APP_LOGIN}/logout?callback_uri=${encodeURI(uri)}`)
      }, 1000)
    } else {
      this.checkLogin()
    }
  }

  async login (token: string): Promise<void> {
    try {
      const loginResult = await AuthService.login(token)
      const auth = setAuthUser(loginResult.accessToken)
      await this.initStore()
      if (auth) {
        this.$router.push({ name: 'Home' })
      } else {
        throw new Error()
      }
    } catch (error) {
      console.error('login', error)
    }
  }

  checkLogin (): void {
      const { query } = this.$route
      const tokenRef = `${query.token_reference}`
      if (query && query.token_reference) {
        this.login(tokenRef)
      } else {
        setTimeout(() => {
          const uri = `${window.location.origin}${this.$route.path}`
          window.location.replace(`${process.env.VUE_APP_LOGIN}?callback_uri=${encodeURI(uri)}`)
        }, 1000)
      }
    }
}
