import auth from '@/midlewares/auth'
import { RouteConfig } from 'vue-router'

const DesktopPosRoute: RouteConfig = {
    path: '/desktop-pos',
    name: 'DesktopPos',
    component: () => import('@/views/desktop-pos/index.vue'),
    meta: {
        middleware: auth
    },
    redirect: '/desktop-pos/cart',
    children: [
        {
            path: 'cart',
            name: 'DesktopCart',
            component: () => import('@/views/desktop-pos/cart.vue')
        },
        {
            path: 'search',
            name: 'SearchProduct',
            component: () => import('@/views/desktop-pos/search-product.vue')
        },
        {
            path: 'payment-channel',
            name: 'PaymentChannel',
            component: () => import('@/views/desktop-pos/payment-channel.vue')
        },
        {
            path: 'card-checkout',
            name: 'CardCheckout',
            component: () => import('@/views/desktop-pos/card-checkout.vue'),
            meta: {
                middleware: auth
            }
        },
        {
            path: 'cash-checkout',
            name: 'CashCheckout',
            component: () => import('@/views/desktop-pos/cash-checkout.vue'),
            meta: {
                middleware: auth
            }
        },
        {
            path: 'order-success',
            name: 'OrderSuccess',
            component: () => import('@/views/desktop-pos/order-success.vue'),
            meta: {
                middleware: auth
            }
        },
        {
            path: 'reprint',
            name: 'RePrintReceipt',
            component: () => import('@/views/desktop-pos/reprint.vue'),
            meta: {
                middleware: auth
            }
        },
        {
            path: 'tax-invoices',
            name: 'TaxInvoices',
            component: () => import('@/views/desktop-pos/tax-invoices.vue'),
            meta: {
                middleware: auth
            }
        },
        {
            path: 'search-order',
            name: 'SearchOrder',
            component: () => import('@/views/desktop-pos/search-order.vue'),
            meta: {
                middleware: auth
            }
        },
        {
            path: 'exchange-cart/:orderId',
            name: 'ExchangeCart',
            component: () => import('@/views/desktop-pos/exchange-cart.vue'),
            meta: {
                middleware: auth
            }
        },
        {
            path: 'exchange-search-product/:orderId',
            name: 'ExchangeSearchProduct',
            component: () => import('@/views/desktop-pos/exchange-search-product.vue'),
            meta: {
                middleware: auth
            }
        }
    ]
}

export default DesktopPosRoute
