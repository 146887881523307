/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ActionContext } from 'vuex'
import PrinterProvider from '@/resources/print.provider'

import dayjs from 'dayjs'
import { getAuthDecode } from '@/utils/auth.utils'

const PrinterService = new PrinterProvider()

const state = {
  printers: []
}

const actions = {
  async getPrinters (store: ActionContext<typeof state, any>): Promise<void> {
    const { data } = await PrinterService.getPrinters(store.rootState.Store.selectBranch.warehouse.id)
    store.commit('SET_PRINTERS', data)
  },
  async printReceipt (store: ActionContext<typeof state, any>, data: any): Promise<any> {
    try {
      let change = 0
      let bill: any = {}
      let paymentName = ''
      let discount = 0
      let amount = 0
      let type = ''

      if (data.type === 'clickcollect') {
        type = store.rootState.ClickCollect.payment.type
        change = store.rootState.ClickCollect.payment.type === 'cash'
          ? store.rootState.ClickCollect.cashAmount - store.rootState.ClickCollect.billResult.net : 0

        bill = store.rootState.ClickCollect.billResult
        paymentName = store.rootState.ClickCollect.payment.name
        amount = store.rootState.ClickCollect.payment.type === 'cash'
          ? store.rootState.ClickCollect.cashAmount : store.rootState.Cart.billResult.net
        discount = store.rootState.ClickCollect.discount
      } else if (data.type === 'exchange-order') {
        type = store.rootState.ExchangeOrder.payment.type
        change = store.rootState.ExchangeOrder.payment.type === 'cash'
          ? store.rootGetters['ExchangeOrder/payMore'] - store.rootState.ExchangeOrder.cashAmount : 0
        bill = store.rootState.ExchangeOrder.billResult
        paymentName = store.rootState.ExchangeOrder.payment.name
        amount = store.rootState.ExchangeOrder.payment.type === 'cash'
          ? store.rootState.ExchangeOrder.cashAmount : store.rootGetters['ExchangeOrder/payMore']
        discount = store.rootState.ExchangeOrder.discount
      } else {
        type = store.rootState.Cart.payment.type
        change = store.rootState.Cart.payment.type === 'cash'
          ? store.rootState.Cart.cashAmount - store.rootState.Cart.bill.net : 0
        bill = store.rootState.Cart.bill
        paymentName = store.rootState.Cart.payment.name
        amount = store.rootState.Cart.payment.type === 'cash'
          ? store.rootState.Cart.cashAmount : store.rootState.Cart.bill.net
        discount = store.rootState.Cart.discount
      }
      const user = getAuthDecode()
      const nameArr = user.name.split(' ')
      const posId = store.rootState.Store?.selectPos?.posId || `POS NO. ${store.rootState.Store.selectBranch.warehouse.id}`
      const payload: any = {
        billId: bill.orderId,
        branchPos: {
          branchId: store.rootState.Store.selectBranch.warehouse.id,
          name: store.rootState.Store.selectBranch.warehouse.name,
          branch: store.rootState.Store.branchAddress.branch,
          posId,
          taxId: store.rootState.Store.branchAddress.taxId ? `TAX#${store.rootState.Store.branchAddress.taxId}` : 'TAX#0-1055-61034-56-1',
          unitId: store.rootState.Store.selectBranch.warehouse.id,
          code: store.rootState.Store.selectBranch.warehouse.code
        },
        payment: {
          name: paymentName,
          type,
          amount,
          change: change < 0 ? 0 : change
        },
        items: bill.skus.map((sku: any) => ({
          ...sku,
          option: (`${ sku.size} ${sku.color}`).trim(),
          option1: sku.size,
          option2: sku.color,
          variant1: 'Size',
          variant2: 'Color',
          image: sku.images[0]
        })),
        discount,
        print: { message: '', status: data.status, ip: data.ip },
        fullPrice: bill.skus.reduce((sum: number, item: any) => sum + item.fullPrice, 0),
        price: bill.net,
        cashier: {
          id: user.userId,
          firstName: nameArr[0],
          lastName: nameArr[1] ? nameArr[1] : ''
        },
        displayTime: dayjs().format('DD-MM-YYYY HH:mm'),
        storeDisplayName: store.rootState.Store.storeInfo.name,
        storeLogo: store.rootState.Store.storeSetting.logoUrl || 'https://new-gentlewoman.s3.ap-southeast-1.amazonaws.com/store-logo/logo-shop.png'
      }
      if (data.localPrint) {
        payload.displayTime = dayjs()
        return payload
      }
        await PrinterService.printReceipt(payload)
        return true
    } catch (err) {
      console.log(err)
      return false
    }
  },
  async printProductPicking (store: ActionContext<typeof state, any>, data: any): Promise<boolean> {
    try {
      const payload = {
        warehouse: {
          id: store.rootState.Store.selectBranch.warehouse.id,
          name: store.rootState.Store.selectBranch.warehouse.name,
          code: store.rootState.Store.selectBranch.warehouse.code
        },
        customerName: data.customerName,
        item: data.item,
        to: data.to,
        print: { message: '', status: 'pending', ip: data.print.ip }
      }
      await PrinterService.printProductPicking(payload)

      return true
    } catch (err) {
      console.log(err)
      return false
    }
  }
}

const mutations = {
  SET_PRINTERS (state: any, printers: any) {
    state.printers = printers
  }
}

const getters = {
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
