// /public/5e3548c2d32cb12606a34fb8/prints/receipt
/* eslint-disable @typescript-eslint/no-explicit-any */
import { getAuthToken } from '@/utils/auth.utils'
import HttpRequest from './http-request'

class PrinterProvider extends HttpRequest {
  constructor () {
    super(process.env.VUE_APP_PRINT_API)
  }

  getPrinters (branchId: string): Promise<any> {
    this.setHeader(getAuthToken())
    return this.get(`/printers/${branchId}`)
  }

  printReceipt (payload: any): Promise<any> {
    this.setHeader(getAuthToken())
    return this.create('/prints/receipt', payload)
  }

  printProductPicking (payload: any): Promise<any> {
    this.setHeader(getAuthToken())
    return this.create('/prints/product-picking', payload)
  }
}

export default PrinterProvider
