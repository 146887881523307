/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { getAuthToken } from '@/utils/auth.utils'
import HttpRequest from './http-request'

class NewReportProvider extends HttpRequest {
  getReport (payload: any): Promise<any> {
    this.setHeader(getAuthToken())
    return this.get('new-report/pos-product-sales', payload)
  }
}

export default NewReportProvider
