










































































































































import { Component, Vue } from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'
import PickPackProvider from '@/resources/pick-pack.provider'
import TrackingBehaviorProvider from '@/resources/tracking-behavior.provider'
import { BehaviorsEventEnum } from '@/utils/behaviorsEvent.enum'
import ProductProvider from '@/resources/product.provider'
import SkuList from './components/sku-list.vue'

const productService = new ProductProvider()
const PickPackService = new PickPackProvider()
const TrackingBehaviorService = new TrackingBehaviorProvider()

@Component({
  components: {
    SkuList
  },
  computed: {
    ...mapGetters({
      order: 'Order/getOrder',
      skus: 'Order/getSkus'
    }),
    ...mapState({
      selectBranch: (state: any) => state.Store.selectBranch
    })
  },
  methods: {
    ...mapActions({
      findOrder: 'Order/findOrder',
      setOrder: 'Order/setOrder',
      setSkus: 'Order/setSkus',
      clearOrder: 'Order/clearOrder',
      addTrackings: 'Order/addTrackings',
      completeOrder: 'Order/completeOrder'
    })
  }
})

export default class PickPack extends Vue {
  search = ''

  skuCode = ''

  loading = false

  findOrder!: any

  setOrder!: any

  setSkus!: any

  selectBranch!: any

  order!: any

  skus!: any

  clearOrder!: any

  addTrackings!: any

  completeOrder!: any

  isDone = false

  isTracking = false

  trackingNo = ''

  get currencySymbol (): string {
    const interOrder = [

      'chatbot_international',
      'online_singapore',
      'online_international',
      'international_manual'
    ]

    const channel = this.order?.channel || ''
    return interOrder.findIndex((inter: string) => inter === channel) === -1 ? '฿' : '$'
  }

  mounted (): void {
    if (this.searchQuery !== '') {
      this.clearOrder()
      this.search = `${this.searchQuery}`
      this.searchOrder()
    }

    if (this.skuQuery !== '') {
      const sku = `${this.skuQuery}`.split('.com/')[1] || `${this.skuQuery}`
      this.search = sku
      this.skuCode = sku
      this.skuValidation()
    }

    if (this.trackingQuery !== '') {
      this.trackingNo = `${this.trackingQuery}`
      this.isTracking = true
      this.tracking()
    }

    if (this.validation() && Object.keys(this.order).length) {
      this.isDone = true
    }
  }

  get searchQuery (): string | (string | null)[] {
    return this.$route.query.search || ''
  }

  get skuQuery (): string | (string | null)[] {
    return this.$route.query.skuCode || ''
  }

  get trackingQuery (): string | (string | null)[] {
    return this.$route.query.tracking || ''
  }

  async searchOrder (): Promise<void> {
    this.clearOrder()

    try {
      this.loading = true

      const order = await this.findOrder(this.search)
      if (order.currentState === 'completed') {
        await TrackingBehaviorService.createEvent({
          orderId: this.order.orderId,
          event: BehaviorsEventEnum.REPEATED,
          warehouse: this.selectBranch.warehouse
        })
      }

      if (!Object.keys(this.order).length) {
        this.$buefy.toast.open({ message: 'Order not found', type: 'is-danger' })
        // eslint-disable-next-line global-require
        new Audio(require('@/assets/sounds/error.mp3')).play()
      }

      const payload = {
        order: this.order,
        countSku: this.order.skus.reduce((sum: any, sku: any) => sum + sku.amount, 0),
        pickpackState: 'scanning',
        warehouse: this.selectBranch.warehouse
      }
      await PickPackService.createPickPackLog(payload)
    } catch (error) {
      this.$buefy.toast.open({ message: 'Order not found', type: 'is-danger' })
      // eslint-disable-next-line global-require
      new Audio(require('@/assets/sounds/error.mp3')).play()
      console.error('searchOrder', error)
    } finally {
      this.loading = false
      this.search = ''
    }
  }

  searchSKU (): void {
    this.skuCode = this.search
    this.skuValidation()
  }

  async skuValidation (): Promise<void> {
    try {
      let isMatch = false
      this.loading = true
      const index = this.skus.findIndex((sku: any) => sku.code === this.skuCode && !sku.isValid)

      if (index === -1) {
        const { data: { results } } = await productService.getProduct({ search: this.skuCode })
        let code = ''
        results.forEach((result: any) => {
          code = result.skus.find((sku: any) => sku.codeTemp === this.skuCode)?.code || null
        })

        const index2 = this.skus.findIndex((sku: any) => sku.code === code && !sku.isValid)
        if (index2 !== -1) {
          isMatch = true
          this.skus[index2].isValid = true
        }
      } else {
        isMatch = true
        this.skus[index].isValid = true
      }

      if (!this.skus.find((sku: any) => sku.isValid === false)) {
        const payload = {
          order: this.order,
          countSku: this.order.skus.reduce((sum: any, sku: any) => sum + sku.amount, 0),
          pickpackState: 'validated',
          warehouse: this.selectBranch.warehouse
        }
        await PickPackService.createPickPackLog(payload)

        this.isDone = true
      }

      if (!isMatch) {
        this.$buefy.toast.open({ message: 'Product not found', type: 'is-danger' })
        // eslint-disable-next-line global-require
        new Audio(await require('@/assets/sounds/error.mp3')).play()
        await TrackingBehaviorService.createEvent({
          orderId: this.order.orderId,
          event: BehaviorsEventEnum.WRONG_PRODUCT,
          warehouse: this.selectBranch.warehouse
        })
      } else {
        this.$buefy.toast.open({ message: 'Correct!', type: 'is-success' })
        this.setSkus(this.skus)
      }
    } catch (error) {
      console.error('skuValidation', error)
    } finally {
      this.loading = false
      this.search = ''
    }
  }

  validation (): boolean {
    for (const sku of this.skus) {
      if (!sku.isValid) return false
    }

    return true
  }

  async tracking (): Promise<void> {
    this.isTracking = !this.isTracking

    if (!this.isTracking && this.trackingNo !== '') {
      this.loading = true

      try {
        await this.addTrackings({ orderId: this.order.orderId, trackingNo: this.trackingNo })

        this.$buefy.toast.open({ message: 'Added Success!', type: 'is-success' })
      } catch (error) {
        this.$buefy.toast.open({ message: 'Added Fail!', type: 'is-danger' })
      } finally {
        this.loading = false
      }
    } else if (this.order.shipping.refCode !== null && this.trackingNo === '') {
      this.trackingNo = this.order.shipping.refCode
    }
  }

  async complete (): Promise<void> {
    this.loading = true

    try {
      if (this.order.currentState === 'ready_to_ship') {
        await this.completeOrder(this.order.orderId)
      }

      await this.findOrder(this.order.orderId)

      const payload = {
        order: this.order,
        countSku: this.order.skus.reduce((sum: any, sku: any) => sum + sku.amount, 0),
        pickpackState: 'completed',
        warehouse: this.selectBranch.warehouse
      }
      await PickPackService.createPickPackLog(payload)
      await TrackingBehaviorService.createEvent({
        orderId: this.order.orderId,
        event: BehaviorsEventEnum.PICK_PACK,
        warehouse: this.selectBranch.warehouse
      })

      this.$buefy.toast.open({ message: 'Order Completed!', type: 'is-success' })

      this.clear()
    } catch (error) {
      this.$buefy.toast.open({ message: 'Order is not completed!', type: 'is-danger' })
      console.error('complete', error)
    } finally {
      this.loading = false
    }
  }

  searchOrderOrSKU (): void {
    if (!Object.keys(this.order).length) {
      this.searchOrder()
    } else {
      this.searchSKU()
    }
  }

  clear (): void {
    this.clearOrder()
    this.search = ''
    this.isDone = false
    this.skuCode = ''
    this.isTracking = false
    this.trackingNo = ''
  }

  back (): void {
    if (!Object.keys(this.order).length) {
      this.$router.push({ name: 'Home' })
    } else {
      this.clear()
    }
  }
}
