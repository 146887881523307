

























import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapActions } from 'vuex'

@Component({
  methods: {
    ...mapActions({
      addItems: 'Cart/addItems'
    })
  }
})
export default class ProductList extends Vue {
  @Prop()
  readonly product!: any

  readonly addItems!: any

  showFullPriceFormat!: any

  addToCart (): void {
    this.addItems(this.product)
  }
}
