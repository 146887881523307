/* eslint-disable @typescript-eslint/no-explicit-any */
import { getAuthToken } from '@/utils/auth.utils'
import HttpRequest from './http-request'

class PromotionCodeProvider extends HttpRequest {
  validatePromotionCode (payload: any, storeToken: string): Promise<any> {
    this.setHeader(getAuthToken())
    return this.create(`/public/${storeToken}/promotion-codes/validate`, payload)
  }

  superValidatePromotionCode (payload: any): Promise<any> {
    this.setHeader(getAuthToken())
    return this.create('/promotion-codes/super-validate', payload)
  }

  getCampaignUsed (memberId: string): Promise<any> {
    this.setHeader(getAuthToken())
    return this.get(`/promotion-codes/${memberId}/get-member-campaign-used`)
  }

  getSpending (memberId: string): Promise<any> {
    this.setHeader(getAuthToken())
    return this.get(`/members/${memberId}/spending-expire`)
  }
}

export default PromotionCodeProvider
