<template>
  <div class="barcode-page">
    <div class="barcode-header">
      <a @click="$router.go(-1)">
        <b-icon
          pack="mdi"
          icon="chevron-left"
          type="is-white"
          style="margin-left: 10px"
        />
      </a>
      <div style="margin: auto;">
        Scan Barcode
      </div>
    </div>
    <div class="barcode-content">
      <p class="barcode-error">
        {{ error }}
      </p>
      <div class="barcode-wraper">
        <StreamBarcodeReader
          @decode="onDecode"
          @init="onInit" />
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import { StreamBarcodeReader } from 'vue-barcode-reader'

@Component({
  components: {
    StreamBarcodeReader
  }
})
export default class ScanBarcode extends Vue {
  result = ''

  error = ''

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get type () {
    return this.$route.query.type ? this.$route.query.type : 'ProductList'
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onDecode (result) {
    if (this.type === 'pickpack-tracking') {
      this.$router.push({ name: 'PickPack', query: { tracking: result } })
    } else {
      this.$router.push({ name: 'ProductList' })
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async onInit (promise) {
    try {
      await promise
    } catch (error) {
      if (error.name === 'NotAllowedError') {
        this.error = 'ERROR: you need to grant camera access permisson'
      } else if (error.name === 'NotFoundError') {
        this.error = 'ERROR: no camera on this device'
      } else if (error.name === 'NotSupportedError') {
        this.error = 'ERROR: secure context required (HTTPS, localhost)'
      } else if (error.name === 'NotReadableError') {
        this.error = 'ERROR: is the camera already in use?'
      } else if (error.name === 'OverconstrainedError') {
        this.error = 'ERROR: installed cameras are not suitable'
      } else if (error.name === 'StreamApiNotSupportedError') {
        this.error = 'ERROR: Stream API is not supported in this browser'
      }
    }
  }
}
</script>

<style scoped>
.barcode-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: red;
  justify-content: center;
  height: calc(100dvh - 60px);
}
.barcode-error {
  padding: 10px;
}
.barcode-wraper {
  width: 320px;
  height: 242px;
  border: 2px solid black;
}
.barcode-header {
  background-color: black;
  height: 60px;
  display: flex;
  color: #fff;
  align-items: center;
}
</style>
