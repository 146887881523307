import dayjs from 'dayjs'
import Vue from 'vue'

Vue.filter('showFullPriceFormat', (number = 0): string => {
  const num = Number(number)
  if (num === 0 || num) {
    return num.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return '-'
})

Vue.filter('showPriceFormat', (number = 0): string => {
  const num = Number(number)
  if (num === 0 || num) {
    return num.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return '-'
})

Vue.filter('formatDateTime', (dateData: Date): string => {
  if (dateData) {
    return dayjs(dateData).format('DD-MM-YYYY HH:mm')
  }
  return '-'
})
