<template>
  <div>
    <div class="qr-content">
      <p class="qr-error">
        {{ error }}
      </p>
      <div class="qr-wraper">
        <qrcode-stream
          @decode="onDecode"
          @init="onInit" />
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import { QrcodeStream } from 'vue-qrcode-reader'

@Component({
  components: {
    QrcodeStream
  }
})
export default class ScanQr extends Vue {
  result = ''

  error = ''

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onDecode (result) {
    this.$emit('onDecode', result)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async onInit (promise) {
    try {
      await promise
    } catch (error) {
      if (error.name === 'NotAllowedError') {
        this.error = 'ERROR: you need to grant camera access permisson'
      } else if (error.name === 'NotFoundError') {
        this.error = 'ERROR: no camera on this device'
      } else if (error.name === 'NotSupportedError') {
        this.error = 'ERROR: secure context required (HTTPS, localhost)'
      } else if (error.name === 'NotReadableError') {
        this.error = 'ERROR: is the camera already in use?'
      } else if (error.name === 'OverconstrainedError') {
        this.error = 'ERROR: installed cameras are not suitable'
      } else if (error.name === 'StreamApiNotSupportedError') {
        this.error = 'ERROR: Stream API is not supported in this browser'
      }
    }
  }
}
</script>

<style scoped>
.qr-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: red;
  justify-content: center;
}
.qr-error {
  padding: 10px;
}
.qr-wraper {
  width: 320px;
  height: 320px;
  border: 2px solid black;
}
</style>
