/* eslint-disable @typescript-eslint/no-explicit-any */
import jwtDecode from 'jwt-decode'
import * as Cookies from 'js-cookie'

const storageKey = 'CampAccessToken'

export const getAuth = (): any => {
  const auth = Cookies.getJSON(storageKey)
  return auth
}

export const getAuthDecode = (): any | null => {
  const auth = Cookies.getJSON(storageKey)
  return auth ? jwtDecode(auth) : null
}

export const setAuthUser = (token: string): boolean => {
  const decoded: any = jwtDecode(token)
  const date = decoded.exp * 1000
  Cookies.set(storageKey, token, {
    expires: new Date(date)
  })
  return true
}

export const clearAuth = (): void => {
  Cookies.remove(storageKey)
}

export const getAuthToken = (): any => {
  const token = { key: '', value: '' }

  if (Cookies.getJSON(storageKey)) {
    token.key = 'Authorization'
    token.value = `Bearer ${Cookies.getJSON(storageKey)}`
  }
  return { [token.key]: token.value }
}

export default {
  setAuthUser,
  getAuth,
  getAuthToken,
  clearAuth,
  getAuthDecode
}
