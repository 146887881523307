/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { getAuthToken } from '@/utils/auth.utils'
import HttpRequest from './http-request'

interface datePamInterface {
  startDate: string
  endDate: string
}

export default class extends HttpRequest {
  createDailyReport (payload: any): Promise<any> {
    this.setHeader(getAuthToken())
    return this.create('/pos-daily-reports', payload)
  }

  getPaymentSummary (date: datePamInterface, warehouse: number): Promise<any> {
    this.setHeader(getAuthToken())
    return this.get(`/new-report/payment-summary?startDate=${date.startDate}&endDate=${date.endDate}&warehouse=${warehouse}`)
  }

  getExportProduct (date: datePamInterface, warehouse: number): Promise<any> {
    this.setHeader(getAuthToken())
    return this.get(`/new-report/export-pro/warehouse?startDate=${date.startDate}&endDate=${date.endDate}&warehouse=${warehouse}&channel=pos`)
  }

  getSummary (date: datePamInterface, warehouse: number): Promise<any> {
    this.setHeader(getAuthToken())
    return this.get(`/new-report/summary/warehouse?startDate=${date.startDate}&endDate=${date.endDate}&warehouse=${warehouse}&channel=pos`)
  }
}
