import '../camp_version'
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import './plugins/buefy.plugin'
import './plugins/filter.plugin'
import './plugins/VeeValidate'
import './plugins/Vmask'
import 'vue-tel-input/dist/vue-tel-input.css'
import '@/assets/css/index.css'
import '@/assets/css/custom-styles.scss'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
