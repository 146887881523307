import { getAuthToken } from '@/utils/auth.utils'
import HttpRequest from './http-request'

class WarehouseProvider extends HttpRequest {
  getWarehouseById (id: number): any {
    this.setHeader(getAuthToken())
    return this.get(`/warehouses/${id}`)
  }
}

export default WarehouseProvider
