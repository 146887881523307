/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { getAuthToken } from '@/utils/auth.utils'
import HttpRequest from './http-request'

class EmployeeReportProvider extends HttpRequest {
  getReportsByDateAndWarehouse (query: any): Promise<any> {
    this.setHeader(getAuthToken())
    return this.get('employee-report/sales-in-branch', query)
  }

  getEmployeeReportsByDateAndWarehouse (id: any, query: any): Promise<any> {
    this.setHeader(getAuthToken())
    return this.get(`employee-report/${id}/by-date-and-warehouse`, query)
  }

  getEmployeeReportsByMonthAndWarehouse (id: any, query: any): Promise<any> {
    this.setHeader(getAuthToken())
    return this.get(`employee-report/${id}/by-months-and-warehouse`, query)
  }

  getEmployeesRanking (query: any): Promise<any> {
    this.setHeader(getAuthToken())
    return this.get('employee-report/report/ranking', query)
  }
}

export default EmployeeReportProvider
