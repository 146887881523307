



































































import { Component, Vue } from 'vue-property-decorator'
import OrderProvider from '@/resources/order.provider'
import { mapActions } from 'vuex'

const OrderService = new OrderProvider()

@Component({
  methods: {
    ...mapActions({
      getStepPricing: 'Cart/getStepPricing'
    })
  }
})
export default class ProductListPage extends Vue {
  getStepPricing!: any

  search = ''

  orders: any[] = []

  columns = [
    {
      field: 'orderId',
      label: 'ID',
      width: '40',
      numeric: true
    },
    {
      field: 'first_name',
      label: 'First Name'
    }
  ]

  loading = false

  get searchQuery (): string | (string | null)[] {
    return this.$route.query.search || ''
  }

  async mounted (): Promise<void> {
    await this.getStepPricing()
    if (this.searchQuery !== '') {
      this.search = `${this.searchQuery}`
      this.searchOrder()
    }
  }

  get type (): any {
    return this.$route.query.type ? this.$route.query.type : 'receipt'
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/explicit-module-boundary-types
  chooseOrder (order: any): void {
    this.$router.push({ name: 'ExchangeOrderCart', params: { orderId: order.orderId } })
  }

  searchOrder (): void {
    this.getOrders()
  }

  async getOrders (): Promise<void> {
    try {
      this.loading = true
      const { data: { results } } = await OrderService.getOrders({
        page: 1,
        limit: 25,
        search: this.search.trim(),
        channel: 'all',
        currentState: 'completed'
      })
      if (results.length === 0) {
        this.$buefy.dialog.alert('ไม่พบ Order')
      }
      this.orders = results
    } catch (err: any) {
      this.$buefy.dialog.alert(err.message)
    } finally {
      this.loading = false
    }
  }
}
