


































































































import { Component, Vue } from 'vue-property-decorator'
import { mapActions, mapState } from 'vuex'
import OrderProvider from '@/resources/order.provider'
import ProductList from './components/product-list.vue'

const orderService = new OrderProvider()

@Component({
  components: { ProductList },
  computed: {
    ...mapState({
      selectBranch: (state: any) => state.Store.selectBranch,
      storeInfo: (state: any) => state.Store.storeInfo,
      stepPricing: (state: any) => state.Cart.stepPricing
    })
  },
  methods: {
    ...mapActions({
      setItems: 'ClickCollect/setItems',
      setBill: 'ClickCollect/setBill',
      setDiscount: 'ClickCollect/setDiscount',
      setPromotionCode: 'ClickCollect/setPromotionCode'
    })
  }
})
export default class Home extends Vue {
  stepPricing!: any

  setBill!: any

  showFullPriceFormat!: any

  setItems!: any

  storeInfo!: any

  selectBranch!: any

  setDiscount!: any

  setPromotionCode!: any

  selected: any[] = []

  order: any = {}

  get cartAmount (): number {
    return this.selected.length
  }

  checkOut (): void {
    this.setItems(this.selected)
    this.setBill(this.order)
    if (this.selected.length === this.order.skus.length) {
      const promo = this.order.promotionCode.code ? this.order.promotionCode : null
      this.setPromotionCode(promo)
      this.setDiscount(promo !== null ? this.order.discount : 0)
    }
    this.$router.push({ name: 'ClickCollectCart' })
  }

  private headers = [
    { label: 'Product List', field: 'productInfo' },
    { label: 'Qty', field: 'amount' },
    { label: 'Price', field: 'price' }
  ]

  private spiltOrderItem (): any[] {
    const result: any[] = []
    this.order.skus.forEach((element: any) => {
      for (let i = 0; i < element.amount; i++) {
        result.push({
          ...element,
          price: element.price / element.amount,
          fullPrice: element.fullPrice / element.amount,
          amount: 1
        })
      }
    })
    return result
  }

  async mounted (): Promise<void> {
    const { data } = await orderService.getOrderById(this.orderId)
    this.order = data
    data.skus = this.spiltOrderItem()
    this.selected = data.skus
  }

  get warehouseName (): string {
    if (!this.selectBranch || this.selectBranch === null) {
      return ''
    }
    return this.selectBranch.warehouse.name
  }

  get orderId (): string {
    return this.$route.params.orderId ? this.$route.params.orderId : ''
  }
}
