



































































































































































import { getAuthDecode } from '@/utils/auth.utils'
import EmployeeReportProvider from '@/resources/employee-report.provider'
import DailyBriefProvider from '@/resources/daily-brief.provider'
import PerformanceReportProvider from '@/resources/performance-report.provider'
import { Component, Vue } from 'vue-property-decorator'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import Report from './components/report.vue'

dayjs.extend(utc)

const EmployeeReportService = new EmployeeReportProvider()
const DailyBriefService = new DailyBriefProvider()
const PerformanceReportService = new PerformanceReportProvider()

@Component({
  components: {
    Report
  }
})

export default class PMS extends Vue {
  isShowReport = false

  user = {
    id: '',
    name: '',
    userId: 0
  }

  fetchReports = [
    {
      sales: 0
    }
  ]

  fetchDailyBrief: any[] = []

  fetchTodaySalesInBranch = 0

  fetchMonthSalesInBranch = 0

  fetchRanking: any[] = []

  fetchTarget: any = {
    id: null,
    name: '',
    bonus: 0,
    pool: 0,
    salesTarget: 0,
    todaySalesTarget: 0,
    salesTotal: 0,
    totalScore: 0,
    gwCategories: []
  }

  loading = false

  mounted (): void {
    this.user = getAuthDecode()
    this.initReports()
  }

  get warehouseName (): string {
    if (!this.selectBranch || this.selectBranch === null) {
      return ''
    }
    return this.selectBranch.warehouse.name
  }

  get selectBranch (): any {
    return this.$store.state.Store.selectBranch
  }

  get salesInMonth (): any {
    return this.fetchReports[0]
  }

  isMyRank (userId: number): string {
    return (userId === this.user.userId) ? 'my-rank' : ''
  }

  async initReports (): Promise<void> {
    try {
      this.loading = true

      await Promise.all([
        this.getTodaySalesInBranch(),
        this.getPerformanceReport(),
        this.getDailyBrief()
      ])

      // await this.getReports()
      // await this.getRanking()
      // await this.getMonthSalesInBranch()
    } catch (error) {
      console.error('initReports', error)
    } finally {
      this.loading = false
    }
  }

  async getDailyBrief (): Promise<void> {
    try {
      const { data } = await DailyBriefService.getDailyBrives()

      const mappedDailyBrief: any = []
      data.results.forEach((report: any) => mappedDailyBrief.push(...report.messages))

      this.fetchDailyBrief = mappedDailyBrief
    } catch (error) {
      console.error('getDailyBrief', error)
    }
  }

  async getReports (): Promise<void> {
    try {
      const end = dayjs()
      // const start = end.subtract(5, 'month')
      const start = end.subtract(1, 'month')
      const query = {
        start: start.format('M-YYYY'),
        end: end.format('M-YYYY'),
        warehouseId: this.selectBranch.warehouse.id
      }

      const { data } = await EmployeeReportService.getEmployeeReportsByMonthAndWarehouse(this.user.userId, query)
      this.fetchReports = data
    } catch (error) {
      console.error('getReports', error)
    }
  }

  async getRanking (): Promise<void> {
    try {
      const end = dayjs()
      const start = end
      const query = {
        start: start.format('M-YYYY'),
        end: end.format('M-YYYY'),
        warehouseId: this.selectBranch.warehouse.id
      }

      const { data } = await EmployeeReportService.getEmployeesRanking(query)

      const found = data.find((rank: any) => rank.id === `${this.user.userId}`)

      if (!found) {
        this.fetchRanking = [
          ...data,
          {
            id: `${this.user.userId}`,
            sales: 0
          }
        ]
      } else {
        this.fetchRanking = data
      }
    } catch (error) {
      console.error('getRanking', error)
    }
  }

  async getTodaySalesInBranch (): Promise<void> {
    try {
      // const query = {
      //   startDate: dayjs().format('YYYY-MM-DD'),
      //   endDate: dayjs().add(1, 'day').format('YYYY-MM-DD'),
      //   warehouseId: this.selectBranch.warehouse.id
      // }

      // const { data } = await EmployeeReportService.getReportsByDateAndWarehouse(query)

      // this.fetchTodaySalesInBranch = data[0]?.sales || 0

      const startDate = dayjs()
                    .hour(0)
                    .minute(0)
                    .second(0)
      const endDate = dayjs()
                    .add(1, 'day')
                    .hour(0)
                    .minute(0)
                    .second(0)
      const { data } = await PerformanceReportService.getPerformanceReport({
        brand: 'all',
        warehouse: this.selectBranch.warehouse.id,
        startDate: startDate.format(),
        endDate: endDate.format()
      })

      this.fetchTodaySalesInBranch = data?.warehouses[0]?.salesTotal || 0
    } catch (error) {
      console.error('getTodaySalesInBranch', error)
    }
  }

  async getMonthSalesInBranch (): Promise<void> {
    try {
      const query = {
        startDate: dayjs()
          .date(1)
          .format('YYYY-MM-DD'),
        endDate: dayjs()
          .date(1)
          .add(1, 'month')
          .format('YYYY-MM-DD'),
        warehouseId: this.selectBranch.warehouse.id
      }

      const { data } = await EmployeeReportService.getReportsByDateAndWarehouse(query)

      this.fetchMonthSalesInBranch = data[0]?.sales || 0
    } catch (error) {
      console.error('getTodaySalesInBranch', error)
    }
  }

  async getPerformanceReport (): Promise<void> {
    try {
      const startDate = dayjs()
                    .date(1)
                    .hour(0)
                    .minute(0)
                    .second(0)
      const endDate = dayjs()
                    .add(1, 'month')
                    .date(1)
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .subtract(1, 'day')
      const { data } = await PerformanceReportService.getPerformanceReport({
        brand: 'all',
        warehouse: this.selectBranch.warehouse.id,
        startDate: startDate.format(),
        endDate: endDate.format()
      })

      const remainDays = dayjs().daysInMonth() - dayjs().date()
      const warehouse = data?.warehouses[0] || null

      if (warehouse) {
        const diffSales = warehouse.salesTarget - warehouse.salesTotal

        this.fetchTarget = {
          ...warehouse,
          todaySalesTarget: (diffSales > 0) ? (diffSales / remainDays) : 0
        }

        this.fetchMonthSalesInBranch = warehouse.salesTotal
      }
    } catch (error) {
      console.error('getPerformanceReport', error)
    }
  }

  getGreetingTime (): string {
    const time = dayjs().hour()
    let timeStr = 'Good Morning'

    if (time >= 12 && time < 18) {
      timeStr = 'Good Afternoon'
    } else if (time >= 18 && time <= 23) {
      timeStr = 'Good Evening'
    }

    return `${timeStr}, ${ this.user.name }`
  }

  /* eslint-disable class-methods-use-this */
  numberFormat (number: number): string {
    const tmpNumber = number.toFixed(2)
    return Number(tmpNumber).toLocaleString('en')
  }

  getRemainPercent (): number {
    return Math.round(((this.fetchTarget.salesTotal / this.fetchTarget.salesTarget) * 100) - 100)
  }

  toggleReport (): void {
    this.isShowReport = !this.isShowReport
  }
}
