/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ActionContext } from 'vuex'
import StoreProvider from '@/resources/store.provider'
import WarehouseProvider from '@/resources/warehouse.provider'
import PosDeviceProvider from '@/resources/pos-device.provider'

const StoreService = new StoreProvider()
const warehouseService = new WarehouseProvider()
const posDeviceProvider = new PosDeviceProvider()

const state = {
  branchs: [],
  paymentChannels: [],
  selectBranch: null,
  storeSetting: null,
  storeInfo: null,
  branchAddress: null,
  branchPos: [],
  selectPos: {
    id: '',
    serialNumber: '',
    posId: '',
    assetId: ''
  }
}

const actions = {
  async initStore (store: ActionContext<typeof state, any>): Promise<void> {
    try {
      const [branchs, paymentChannels, storeSetting, storeInfo] = await Promise.all([
        StoreService.getUserBranch(),
        StoreService.getPaymentChannel(),
        StoreService.getStoreSetting(),
        StoreService.getStoreByToken()
      ])

      if (store.state.selectBranch) {
        const selected: any = store.state.selectBranch
        const branch = branchs.data.find((b: any) => b.warehouse.id === selected.warehouse.id)

        store.commit('SET_SELECT_BRANCH', branch)
      }

      store.commit('SET_BRANCHS', branchs.data)
      store.commit('SET_PAYMENT_CHANNELS', paymentChannels.data)
      store.commit('SET_STORE_SETTING', storeSetting.data)
      store.commit('SET_STORE_INFO', storeInfo.data)
    } catch (err) {
      console.error('initStore', err)
    }
  },
  async initPayment (store: ActionContext<typeof state, any>): Promise<void> {
    try {
      const paymentChannels = await StoreService.getPaymentChannel()
      store.commit('SET_PAYMENT_CHANNELS', paymentChannels.data)
    } catch (err) {
      console.error('initStore', err)
    }
  },
  setSelectBranch (store: ActionContext<typeof state, any>, data: any): void {
    store.commit('SET_SELECT_BRANCH', data)
    store.dispatch('setSelectedBranchAddress', data?.warehouse?.id || null)
  },
  setSelectPos (store: ActionContext<typeof state, any>, data: any): void {
    store.commit('SET_SELECT_POS', data)
  },
  async setSelectedBranchAddress (store: ActionContext<typeof state, any>, warehouseId: number): Promise<void> {
    const defaultAddress = {
      branchId: 964,
      name: 'website',
      channel: 'web',
      taxId: '0-1055-61034-56-1',
      address: 'บริษัท เจนเทิลวูแมน จำกัด (สำนักงานใหญ่)\n999/9 อาคารดิออฟฟิศเศส แอท เซ็นทรัลเวิลด์',
      address2: 'ห้องเลขที่ OFMH 3806-3812 ชั้นที่ 38 ถนนพระราม 1',
      subDistrict: 'ปทุมวัน',
      district: 'ปทุมวัน',
      stateProvince: 'กรุงเทพมหานคร',
      postcode: '10330',
      country: 'ประเทศไทย'
    }
    if (warehouseId) {
      const { data: warehouseData } = await warehouseService.getWarehouseById(warehouseId)
      if (warehouseData) {
        store.commit('SET_BRANCH_ADDRESS', warehouseData)
      }
    } else {
      store.commit('SET_BRANCH_ADDRESS', defaultAddress)
    }
  },
  async setBranchPos (store: ActionContext<typeof state, any>, warehouseId: number): Promise<void> {
    if (warehouseId) {
      store.commit('SET_BRANCH_POS', [])
      const { data: posList } = await posDeviceProvider.getPosDeviceByWarehouseId({ warehouse: warehouseId, status: 'active' })
      if (posList) {
        store.commit('SET_BRANCH_POS', posList)
      }
    }
  }
}

const mutations = {
  SET_BRANCHS (state: any, data: Array<any>): void {
    state.branchs = data
  },
  SET_PAYMENT_CHANNELS (state: any, data: Array<any>): void {
    state.paymentChannels = [...data]
  },
  SET_SELECT_BRANCH (state: any, data: Array<any>): void {
    state.selectBranch = data
  },
  SET_STORE_SETTING (state: any, data: Array<any>): void {
    state.storeSetting = data
  },
  SET_STORE_INFO (state: any, data: Array<any>): void {
    state.storeInfo = data
  },
  SET_BRANCH_ADDRESS (state: any, data: Array<any>): void {
    state.branchAddress = data
  },
  SET_BRANCH_POS (state: any, data: Array<any>): void {
    state.branchPos = data
  },
  SET_SELECT_POS (state: any, data: Array<any>): void {
    state.selectPos = data
  }
}

const getters = {
  storeInfo: (state: any) => state.storeInfo,
  storeSetting: (state: any) => state.storeSetting,
  paymentChannels: (state: any) => state.paymentChannels,
  branchAddress: (state: any) => state.branchAddress,
  branchPos: (state: any) => state.branchPos
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
