import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { getAuth } from '@/utils/auth.utils'
import auth from '@/midlewares/auth'
import DesktopPosRoute from './pos.route'
import ProductList from '../views/product-list/index.vue'
import ProductEventList from '../views/product-event-list/index.vue'
import Cart from '../views/cart/index.vue'
import Nationality from '../views/nationality/index.vue'
import Payment from '../views/payment/index.vue'
import CheckoutByCard from '../views/checkout/check-out-by-card.vue'
import CheckoutByCash from '../views/checkout/check-out-by-cash.vue'
import UserInfo from '../views/user-info/index.vue'
import PickPack from '../views/pick-pack/index.vue'
import Login from '../views/Login.vue'
import P404 from '../views/404.vue'
import Home from '../views/home/index.vue'
import Print from '../views/print/index.vue'
import ScanQr from '../views/scan-qr/index.vue'
import ScanBarcode from '../views/scan-barcode/index.vue'
import ClickCollect from '../views/click-collect/index.vue'
import ClickCollectCart from '../views/click-collect/review-click-collect.vue'
import ExchangeOrder from '../views/exchange-order/index.vue'
import ExchangeOrderCart from '../views/exchange-order/exchange-order-cart.vue'
import PMS from '../views/pms/index.vue'
import Summary from '../views/payment-summary/index.vue'
import PaymentSummary from '../views/payment-summary/summary-daily.vue'
import DailyOrder from '../views/daily-order/index.vue'
import SaleProduct from '../views/sale-product/index.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/product-list',
    name: 'ProductList',
    component: ProductList,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/product-event-list',
    name: 'ProductEventList',
    component: ProductEventList,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/nationality',
    name: 'Nationality',
    component: Nationality,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/payment',
    name: 'Payment',
    component: Payment,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/checkout-by-card',
    name: 'CheckoutByCard',
    component: CheckoutByCard,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/click-collect/cart',
    name: 'ClickCollectCart',
    component: ClickCollectCart,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/click-collect/:orderId',
    name: 'ClickCollect',
    component: ClickCollect,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/checkout-by-cash',
    name: 'CheckoutByCash',
    component: CheckoutByCash,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/print',
    name: 'Print',
    component: Print,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/user-info',
    name: 'UserInfo',
    component: UserInfo,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/pick-pack',
    name: 'PickPack',
    component: PickPack,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/scan-qr',
    name: 'ScanQr',
    component: ScanQr,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/exchange-order',
    name: 'ExchangeOrder',
    component: ExchangeOrder,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/exchange-order/:orderId',
    name: 'ExchangeOrderCart',
    component: ExchangeOrderCart,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/sale-product',
    name: 'SaleProduct',
    component: SaleProduct,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/scan-barcode',
    name: 'ScanBarcode',
    component: ScanBarcode,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/pms',
    name: 'PMS',
    component: PMS,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/summary',
    name: 'Summary',
    component: Summary,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/summary/payment',
    name: 'PaymentSummary',
    component: PaymentSummary,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/daily-order',
    name: 'DailyOrder',
    component: DailyOrder,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Login
  },
  {
    path: '*',
    name: '404',
    component: P404
  },
  DesktopPosRoute
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to: any, from, next) => {
  if (to.name !== 'Login' && !getAuth()) {
    return next({
      name: 'Login'
    })
  }
  if (from.name === 'OrderSuccess' && ['PaymentChannel', 'CardCheckout', 'CashCheckout'].includes(to.name)) {
    return next(false)
  }
  return next()
})

export default router
