import { getAuthToken } from '@/utils/auth.utils'
import HttpRequest from './http-request'

class PosDeviceProvider extends HttpRequest {
  getPosDeviceByWarehouseId (payload: any): any {
    this.setHeader(getAuthToken())
    return this.get('/pos-management/warehouse', payload)
  }
}

export default PosDeviceProvider
