


























































































import { Component, Prop, Vue } from 'vue-property-decorator'
import dayjs from 'dayjs'

@Component
export default class Report extends Vue {
  @Prop()
  public reports!: any

  public isOpen = 0

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, class-methods-use-this
  getDateFormat (month: any): string {
    const tmpMonth = dayjs().month(month.month - 1).format('MMMM')
    return `${tmpMonth} ${month.year}`
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/explicit-module-boundary-types
  numberFormat (number: any): string {
    if (!number) {
      return Number(0).toLocaleString('en')
    }
    const tmpNumber = number.toFixed(2)
    return Number(tmpNumber).toLocaleString('en')
  }
}
