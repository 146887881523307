/* eslint-disable @typescript-eslint/no-explicit-any */
import { getAuthToken } from '@/utils/auth.utils'
import HttpRequest from './http-request'

class StoreProvider extends HttpRequest {
  getUserBranch (): Promise<any> {
    this.setHeader(getAuthToken())
    return this.get('/user/branchs')
  }

  getStoreByToken (): Promise<any> {
    this.setHeader(getAuthToken())
    return this.get('stores/info/token')
  }

  getPaymentChannel (): Promise<any> {
    this.setHeader(getAuthToken())
    return this.get('/payment-channel?channel=pos')
  }

  getStoreSetting (): Promise<any> {
    this.setHeader(getAuthToken())
    return this.get('store-settings')
  }

  getStepPricing (): Promise<any> {
    this.setHeader(getAuthToken())
    return this.get('/step-pricing/object')
  }
}

export default StoreProvider
