<template>
  <div class="container-close-account">
    <div class="close-account-header">
      <router-link :to="{ name: 'Summary' }">
        <b-icon
          pack="mdi"
          icon="chevron-left"
          type="is-white"
          class="ml-10"
        />
      </router-link>
      <div class="m-auto">
        <span> {{ selectBranch.warehouse.name }} </span>
      </div>
    </div>
    <p class="text-topic-payment">
      ปิดยอดขายประจำวัน
    </p>
    <div>
      <div class="payment-account">
        <table>
          <thead>
            <tr class="border-bottom">
              <th class="w-34">
                <p class="payment-head">
                  ช่องทาง
                </p>
              </th>
              <th class="w-33">
                <p class="payment-head">
                  ยอดรับชำระ
                </p>
              </th>
              <th class="w-33">
                <p class="payment-head">
                  ยอดในระบบ
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(payment, indexPayment) in dailySummary.payments"
              :key="`payment-${indexPayment}`"
              class="border-bottom"
            >
              <td>
                <div>
                  <div class="logo">
                    <img
                      :src="payment.logo"
                      :alt="payment.name" />
                  </div>
                  <p class="payment-name">
                    {{ payment.name }}
                  </p>
                </div>
              </td>
              <td>
                <p class="payment-name">
                  {{ payment.inputAmount | showPriceFormat }}
                </p>
              </td>
              <td>
                <p class="payment-name">
                  {{ paymentStore(payment.name) | showPriceFormat }}
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p class="payment-total">
                  รวม
                </p>
              </td>
              <td>
                <p class="payment-total-num">
                  {{ totalPayment || 0 | showPriceFormat }}
                </p>
              </td>
              <td>
                <p class="payment-total-num">
                  {{ totalPosPayment | showPriceFormat }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="container-btn">
          <b-button
            type="is-success"
            class="w-full"
            outlined
            :loading="loading"
            @click="exportSummary()">
            EXPORT รายงาน
          </b-button>
        </div>
        <div class="container-btn">
          <b-button
            type="is-primary"
            class="w-full"
            outlined
            :loading="loading"
            @click="saveData()">
            บันทีกลงระบบ
          </b-button>
        </div>
        <div class="container-btn">
          <b-button
            class="w-full"
            outlined
            @click="$router.push({ name: 'Home' })">
            กลับหน้าหลัก
          </b-button>
        </div>
        <!-- <div
          v-if="showPdf"
          v-show="open">
          <export-pdf
            id="pdf"
            :summary="summary"
            :products="products"
            :order-summary="orderSummary"></export-pdf>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable class-methods-use-this */

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Component, Vue } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { ExportToCsv } from 'export-to-csv'
import SummaryDailyProvider from '@/resources/summary-daily.provider'
// import ExportPdf from './components/export-pdf.vue'

const SummaryDailyService = new SummaryDailyProvider()

dayjs.extend(utc)
@Component({
  // components: { ExportPdf },
  computed: {
    ...mapGetters({
      dailySummary: 'DailySale/dailySummary'
    }),
    ...mapState({
      selectBranch: (state) => state.Store.selectBranch
    })
  }
})
export default class SummaryDaily extends Vue {
  dailySummary

  showPriceFormat

  showPdf = false

  products = []

  selectBranch

  posStore = []

  orderSummary = null

  date = new Date(dayjs().format())

  summary = null

  open = false

  loading = false

  async mounted () {
    this.loading = true
    this.showPdf = false

    if (!this.dailySummary.date) {
      this.$router.push({ name: 'Summary' })
    } else {
      await this.getSummary()
      await this.getExportProduct()
      await this.getPaymentSummary()
    }
    this.loading = false
  }

  get totalPayment () {
    return this.dailySummary?.payments.reduce((total, acc) => total + acc?.inputAmount || 0, 0) || 0
  }

  get totalPosPayment () {
    return this.orderSummary?.totalBillNet || 0
  }

  async saveData () {
    this.loading = true

    try {
      const form = {
        date: this.formatDate(this.dailySummary.date),
        warehouse: { ...this.selectBranch.warehouse },
        payments: this.dailySummary.payments.map((payment) => ({
          ...payment,
          amount: this.paymentStore(payment.name)
        }))
      }
      this.summary = {
        ...form,
        printedDate: this.date
      }

      await SummaryDailyService.createDailyReport(form)
      await this.exportCSV()

      this.$buefy.dialog.alert({
        title: 'สำเร็จ',
        message: 'บันทีกข้อมูลสำเร็จ หากต้องการแก้ไขข้อมูลกรุณาปิดยอดใหม่อีกครั้ง',
        type: 'is-success',
        confirmText: 'กลับหน้าหลัก',
        onConfirm: () => {
          this.$router.push({ name: 'Home' })
        },
        customClass: 'custom-dialog'
      })
    } catch (err) {
      console.error(err)
    }
    this.loading = false
  }

  async exportSummary () {
    this.loading = true
    await this.exportCSV()
    this.loading = false
  }

  async exportCSV () {
    const form = {
      date: this.formatDate(this.dailySummary.date),
      warehouse: { ...this.selectBranch.warehouse },
      payments: this.dailySummary.payments.map((payment) => ({
        ...payment,
        amount: this.paymentStore(payment.name)
      }))
    }
    this.summary = {
      ...form,
      printedDate: this.date
    }
    const payments = this.summary?.payments || []
    const mapped = payments.map((payment) => {
      const amount = payment?.amount || 0
      const inputAmount = payment?.inputAmount || 0
      const diffAmountInputAmount = amount - inputAmount

    return {
        'Payment Channel': payment.name,
        'ยอดรับชำระ': inputAmount,
        'ยอดรายขายตามระบบ': amount,
        'ผลต่าง': diffAmountInputAmount,
        'สาขา': this.summary?.warehouse?.name || '',
        'สาเหตุ': ''
      }
    })

    mapped.push({
      'Payment Channel': 'Total',
      'ยอดรับชำระ': this.totalPayment,
      'ยอดรายขายตามระบบ': this.totalPosPayment,
      'ผลต่าง': this.totalPosPayment - this.totalPayment,
      'สาขา': this.summary?.warehouse?.name || '',
      'สาเหตุ': ''
    })

    const options = {
      filename: `DailyReport-${dayjs(this.dailySummary.date).format('DD-MM-YYYY')}`,
      showLabels: true,
      useKeysAsHeaders: true
    }

    const csvExporter = new ExportToCsv(options)
    csvExporter.generateCsv(mapped)
  }

  formatDate (_date) {
    return dayjs(_date)
            .set('hour', 0)
            .set('minute', 0)
            .set('second', 0)
            .set('milliseconds', 0)
            .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
  }

  formatStartEndDate (_date) {
    const endDate = dayjs(_date).add(1, 'day').set('hour', 0).set('minute', 0)
                    .set('second', 0)
                    .set('milliseconds', 0)
                    .utc()
                    .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
    const startDate = dayjs(_date).set('hour', 0).set('minute', 0)
                      .set('milliseconds', 0)
                      .set('second', 0)
                      .utc()
                      .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
    return {
      startDate,
      endDate
    }
  }

  paymentStore (channel) {
    return this.posStore.find((pos) => {
      if (pos.channel === channel) {
        return pos
      }
      return 0
    })?.amount || 0
  }

  async getExportProduct () {
    const { data } = await SummaryDailyService.getExportProduct(this.formatStartEndDate(this.dailySummary.date), this.selectBranch.warehouse.id)
    this.products = data
  }

  async getPaymentSummary () {
    const { data } = await SummaryDailyService.getPaymentSummary(this.formatStartEndDate(this.dailySummary.date), this.selectBranch.warehouse.id)
    this.posStore = data
  }

  async getSummary () {
    const { data } = await SummaryDailyService.getSummary(this.formatStartEndDate(this.dailySummary.date), this.selectBranch.warehouse.id)
    this.orderSummary = data
  }
}
</script>
<style scoped>
.w-34 {
  width: 34% !important;
}
.w-33 {
  width: 33% !important;
}
.m-auto {
  margin: auto;
}
.ml-10 {
  margin-left: 10px !important;
}
.w-full {
  width: 100% !important;
}
.container-close-account {
  padding-bottom: 20px;
}
.close-account-header {
  background-color: black;
  height: 60px;
  display: flex;
  color: #fff;
  align-items: center;
}
.text-topic-payment {
  text-align: center;
  font-size: 32px;
  color: black;
}
.payment-head {
  text-align: center;
  padding: 10px 0;
}
.payment-name {
  text-align: center;
}
.payment-total {
  text-align: left;
  margin-left: 10px;
}
.payment-total-num {
  text-align: center;
}
.payment-account {
  margin-top: 20px;
  height: calc(100dvh - 150px);
  overflow: auto;
}
.payment-account  table {
  margin: 0 auto;
  width: 80%;
}
.payment-account td {
  padding: 20px 0 !important;
}
.border-bottom {
  border-bottom: 0.5px solid rgb(96, 96, 96);
}
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo img {
  width: 50%;
}
.b-numberinput input[type="number"] {
  text-align: right !important;
}
.container-btn {
  width: 80%;
  margin: 20px auto;
}
</style>
