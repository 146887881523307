/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import * as humps from 'humps'

class HttpRequest {
  private axiosInstance: AxiosInstance

  constructor (url = process.env.VUE_APP_API) {
    this.axiosInstance = axios.create({
      baseURL: url,
      timeout: 120000
    })

    this.axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => config, (error) => Promise.reject(error))

    this.axiosInstance.interceptors.response.use((response: AxiosResponse) => {
      const newResponse = response
      newResponse.data = humps.camelizeKeys(response.data)
      return newResponse.data
    }, (error: AxiosError) => {
      if (error && error.response && error.response.data) {
        return Promise.reject(error.response.data)
      }
      const errorFail = {
        code: 503,
        message: error.message
      }
      throw errorFail
    })
  }

  setHeader (header: object) {
    this.axiosInstance.defaults.headers.common = header
    this.axiosInstance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
  }

  get (methodName: string, data?: object) {
    return this.axiosInstance.get(methodName, {
      params: data
    })
  }

  create (methodName: string, data: object) {
    return this.axiosInstance.post(methodName, data)
  }

  patch (methodName: string, data: object) {
    return this.axiosInstance.patch(methodName, data)
  }

  update (methodName: string, data: object) {
    return this.axiosInstance.put(methodName, data)
  }

  delete (methodName: string, param?: object, data?: object) {
    return this.axiosInstance.delete(methodName, {
      params: param,
      data
    })
  }

  getByPagination (endpoint: string, query: any) {
    const newQuery = JSON.parse(JSON.stringify(query))

    delete newQuery.sortDesc
    delete newQuery.itemsPerPage

    if (Array.isArray(query.sortDesc)) {
      newQuery.sortOrder = query.sortDesc[0] ? 'desc' : 'asc'
    } else if (query.sortDesc) {
      newQuery.sortOrder = query.sortDesc ? 'desc' : 'asc'
    }

    // newQuery.sortBy = '_id'

    // if (Array.isArray(query.sortBy) && query.sortBy[0] && query.sortBy[0] !== 'id') {
    //   newQuery.sortBy = query.sortBy[0]
    // } else if (query.sortBy && !Array.isArray(query.sortBy) && query.sortBy !== 'id') {
    //   newQuery.sortBy = query.sortBy
    // }

    newQuery.page = query.page || 1
    newQuery.limit = query.limit || query.itemsPerPage || 25
    newQuery.search = query.search || null
    return this.axiosInstance.get(endpoint, { params: newQuery })
  }

  request (type: string, url: string, data: object) {
    switch (type) {
      case 'GET':
        return axios.get(url, { params: data })
      case 'POST':
        return axios.post(url, data)
      case 'PUT':
        return axios.put(url, data)
      case 'DELETE':
        return axios.delete(url, data)
      default:
        return axios.get(url, { params: data })
    }
  }
}

export default HttpRequest
