<template>
  <div class="qr-page">
    <div class="qr-header">
      <a @click="$router.go(-1)">
        <b-icon
          pack="mdi"
          icon="chevron-left"
          type="is-white"
          style="margin-left: 10px"
        />
      </a>
      <div style="margin: auto;">
        Scan QR
      </div>
    </div>
    <div class="qr-content">
      <p class="qr-error">
        {{ error }}
      </p>
      <div class="qr-wraper">
        <qrcode-stream
          @decode="onDecode"
          @init="onInit" />
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import { QrcodeStream } from 'vue-qrcode-reader'

@Component({
  components: {
    QrcodeStream
  }
})
export default class ScanQr extends Vue {
  result = ''

  error = ''

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get type () {
    return this.$route.query.type ? this.$route.query.type : 'ProductList'
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onDecode (result) {
    const resultArr = result.split('/')
    if (this.type === 'pickpack-order') {
      this.$router.push({ name: 'PickPack', query: { search: resultArr[resultArr.length - 1] } })
    } else if (this.type === 'pickpack-sku') {
      this.$router.push({ name: 'PickPack', query: { skuCode: resultArr[resultArr.length - 1] } })
    } else if (this.type === 'clickcollect') {
      this.$router.push({ name: 'ClickCollect', params: { orderId: result } })
    } else if (this.type === 'exchange-order') {
      this.$router.push({ name: 'ExchangeOrder', query: { orderId: result } })
    } else if (resultArr.length > 1) {
      this.$router.push({ name: 'ProductList', query: { search: resultArr[resultArr.length - 1] } })
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async onInit (promise) {
    try {
      await promise
    } catch (error) {
      if (error.name === 'NotAllowedError') {
        this.error = 'ERROR: you need to grant camera access permisson'
      } else if (error.name === 'NotFoundError') {
        this.error = 'ERROR: no camera on this device'
      } else if (error.name === 'NotSupportedError') {
        this.error = 'ERROR: secure context required (HTTPS, localhost)'
      } else if (error.name === 'NotReadableError') {
        this.error = 'ERROR: is the camera already in use?'
      } else if (error.name === 'OverconstrainedError') {
        this.error = 'ERROR: installed cameras are not suitable'
      } else if (error.name === 'StreamApiNotSupportedError') {
        this.error = 'ERROR: Stream API is not supported in this browser'
      }
    }
  }
}
</script>

<style scoped>
.qr-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: red;
  justify-content: center;
  height: calc(100dvh - 60px);
}
.qr-error {
  padding: 10px;
}
.qr-wraper {
  width: 320px;
  height: 320px;
  border: 2px solid black;
}
.qr-header {
  background-color: black;
  height: 60px;
  display: flex;
  color: #fff;
  align-items: center;
}
</style>
