/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ActionContext } from 'vuex'
import StoreProvider from '@/resources/store.provider'
import { calculateStepPricing } from '@/utils/cart.utils'
import PromotionCodePrivider from '@/resources/promotion-code.provider'
import OrderProvider from '@/resources/order.provider'
import ProductProvider from '@/resources/product.provider'
import { CheckAddToCartMaximum } from '@/utils/check-maximum-product.utils'
import { mapProduct } from '@/utils/product.utils'

const StoreService = new StoreProvider()
const PromotionCodeService = new PromotionCodePrivider()
const OrderService = new OrderProvider()
const ProductService = new ProductProvider()

const state = {
  stepPricing: {},
  items: [],
  promotionCode: null,
  discount: 0,
  tel: null,
  payment: {
    id: null
  },
  cashAmount: 0,
  bill: null,
  nationality: 'Thailand',
  gender: null
}

const actions = {
  async getStepPricing (store: ActionContext<typeof state, any>): Promise<void> {
    const { data } = await StoreService.getStepPricing()
    store.commit('SET_STEP_PRICING', data)
  },
  async validateCode (store: ActionContext<typeof state, any>, code: string): Promise<any> {
    try {
      const items = calculateStepPricing(store.state.items)
      const { data } = await PromotionCodeService.validatePromotionCode({
        code,
        member: { tel: store.state.tel },
        channel: 'pos',
        items: items.map((item: any) => ({ ...item, skuId: item.id, brand: { id: 1, name: 'gentlewoman' } }))
      }, store.rootState.Store.storeInfo.id)
      if (data.success) {
        const promotionCodePayload = {
          id: data.promotionCode.id,
          code: data.promotionCode.code,
          discountNumber: data.promotionCode.discountNumber,
          discountType: data.promotionCode.discountType,
          maxPrice: data.promotionCode.maxPrice,
          minPrice: data.promotionCode.minPrice,
          amount: data.promotionCode.amount,
          channel: data.promotionCode.channel
        }
        store.commit('SET_DISCOUNT', data.discount)
        store.commit('SET_PROMOTION_CODE', promotionCodePayload)
      }
      if (data.success) {
        return { success: true, message: 'This code can be use.' }
      }
      return { success: data.success, message: data.error.message }
    } catch (err: any) {
      return { message: err.message, success: false }
    }
  },
  async addToCart (store: ActionContext<typeof state, any>, code: string): Promise<any> {
    const { selectBranch } = store.rootState.Store
    const { calculatedItems } = store.getters
    const { data } = await ProductService.getProductManyByCodes([code])

    if (!data?.length) {
      const error = {
        code: 400,
        message: 'Product not found!'
      }
      throw error
    }

    const skus = mapProduct(data, selectBranch, code)
    const product = data[0]
    const foundSku = skus.find((sku: any) => sku.code === code)
    const stock = foundSku?.stock || null

    if (!foundSku || !stock) {
      const error = {
        code: 400,
        message: 'Product not found!',
        product,
        sku: foundSku
      }
      throw error
    }

    const inCartQty = calculatedItems.reduce((sum: number, item: any) => {
      if (item.id === foundSku.id) {
        return sum + item.amount
      }
      return sum
    }, 0)

    const stockQty = stock.onHandQty - stock.onReservedQty - inCartQty
    if (stockQty <= 0) {
      const error = {
        code: 400,
        message: 'Stock not enough.',
        product,
        sku: foundSku
      }

      throw error
    }

    const addToCartMaxCheckResult = await CheckAddToCartMaximum(calculatedItems, foundSku)

    if (!addToCartMaxCheckResult.isSuccess) {
      const error = {
        code: 400,
        message: 'This product have reached the purchase limit.'
      }

      throw error
    }

    store.commit('ADD_ITEMS', {
      ...foundSku,
      stock
    })
    store.dispatch('clearDiscount')
  },
  clearDiscount (store: ActionContext<typeof state, any>) {
    store.commit('SET_DISCOUNT', 0)
    store.commit('SET_PROMOTION_CODE', null)
  },
  addItems (store: ActionContext<typeof state, any>, item: any): void {
    store.commit('ADD_ITEMS', item)
    store.dispatch('clearDiscount')
  },
  removeItems (store: ActionContext<typeof state, any>, skuId: string): void {
    store.commit('REMOVE_ITEMS', skuId)
    store.dispatch('clearDiscount')
  },
  clearCartItems (store: ActionContext<typeof state, any>): void {
    store.commit('CLEAR_ITEMS')
  },
  setTel (store: ActionContext<typeof state, any>, tel: string | null): void {
    store.commit('SET_TEL', tel)
  },
  setPromotionCode (store: ActionContext<typeof state, any>, promotion: null): void {
    store.commit('SET_PROMOTION_CODE', promotion)
  },
  setNationality (store: ActionContext<typeof state, any>, nation: string | null): void {
    store.commit('SET_NATIONALITY', nation)
  },
  setGender (store: ActionContext<typeof state, any>, payload: string | null): void {
    store.commit('SET_GENDER', payload)
  },
  setPayment (store: ActionContext<typeof state, any>, payment: string | null): void {
    store.commit('SET_PAYMENT', payment)
  },
  setCashAmount (store: ActionContext<typeof state, any>, number: number): void {
    store.commit('SET_CASH_AMOUNT', number)
  },
  async createOrder (store: ActionContext<typeof state, any>): Promise<any> {
    try {
      const skus = calculateStepPricing(store.state.items)
      const { discount, promotionCode, tel, payment, nationality, gender } = store.state
      const expire = new Date()
      const { warehouse } = store.rootState.Store.selectBranch
      const note = ''
      const paymentChannel = store.rootState.Store.paymentChannels.find((pm: any) => pm.id === payment.id)
      let posData = null
      if (store.rootState.Store.selectPos.id && store.rootState.Store.selectPos.posId) {
        posData = {
          id: store.rootState.Store.selectPos.id,
          posId: store.rootState.Store.selectPos.posId
        }
      }
      const branchData = {
        branch: store.rootState.Store.branchAddress.branch,
        branchId: store.rootState.Store.branchAddress.id,
        code: store.rootState.Store.branchAddress.code,
        name: store.rootState.Store.branchAddress.name,
        taxId: store.rootState.Store.branchAddress.taxId,
        unitId: store.rootState.Store.branchAddress.id
      }
      const payload = {
        skus,
        discount,
        promotionCode,
        tel,
        expire,
        warehouse,
        note,
        payments: [{
          debtAmount: store.getters.net,
          payOrder: 1,
          sumDebtAmount: store.getters.net,
          channel: paymentChannel.name,
          channelId: paymentChannel.id,
          channelType: paymentChannel.type,
          deadlineDate: expire,
          note: '',
          paidDate: expire,
          receivedCash: paymentChannel.type === 'cash' ? store.state.cashAmount : 0,
          changeAmount: paymentChannel.type === 'cash' ? store.state.cashAmount - store.getters.net : 0
        }],
        nationality,
        gender,
        pos: posData,
        branch: branchData
      }
      const { data } = await OrderService.createOrder(payload)
      store.commit('SET_BILL', data)

      return {
        success: true,
        orderId: data.orderId,
        message: 'success'
      }
    } catch (err: any) {
      return {
        success: false,
        orderId: null,
        message: err?.message || ''
      }
    }
  },
  clearCart (store: ActionContext<typeof state, any>) {
    if (store.state.bill != null) {
      store.commit('CLEAR_CART')
    }
  }
}

const mutations = {
  SET_BILL (state: any, bill: any) {
    state.bill = bill
  },
  CLEAR_CART (state: any) {
    state.items = []
    state.promotionCode = null
    state.discount = 0
    state.tel = null
    state.payment = 'card'
    state.cashAmount = 0
    state.bill = null
    state.nationality = 'Thailand'
    state.gender = null
  },
  SET_STEP_PRICING (state: any, data: any) {
    state.stepPricing = data
  },
  SET_PROMOTION_CODE (state: any, data: any) {
    state.promotionCode = data
  },
  SET_DISCOUNT (state: any, number: number) {
    state.discount = number
  },
  ADD_ITEMS (state: any, data: any): void {
    state.items.push(data)
  },
  REMOVE_ITEMS (state: any, skuId: string): void {
    const index = state.items.findIndex((item: any) => item.id === skuId)
    state.items.splice(index, 1)
  },
  SET_TEL (state: any, tel: string | null): void {
    state.tel = tel
  },
  CLEAR_ITEMS (state: any) {
    state.items = []
  },
  SET_NATIONALITY (state: any, nation: string) {
    state.nationality = nation
  },
  SET_GENDER (state: any, payload: string) {
    state.gender = payload
  },
  SET_PAYMENT (state: any, payment: string) {
    state.payment = payment
  },
  SET_CASH_AMOUNT (state: any, num: number) {
    state.cashAmount = num
  }
}

const getters = {
  cartAmount: (state: any) => state.items.length,
  payment: (state: any) => state.payment,
  calculatedItems: (state: any): any[] => calculateStepPricing(state.items),
  discount: (state: any) => state.discount,
  fullPrice: (state: any) => getters.calculatedItems(state).reduce((sum: number, item: any) => sum + item.fullPrice, 0),
  totalPrice: (state: any) => getters.calculatedItems(state).reduce((sum: number, item: any) => sum + item.price, 0),
  net: (state: any) => {
    const total = getters.totalPrice(state) - state.discount
    return total < 0 ? 0 : total
  },
  tel: (state: any) => state.tel,
  nationality: (state: any) => state.nationality,
  gender: (state: any) => state.gender,
  bill: (state: any) => state.bill,
  groupedItems: (state: any) => getters.calculatedItems(state).reduce((arr: any[], v: any) => {
      const index = arr.findIndex((i: any) => i.id === v.id)
      if (index === -1) {
        return [
          ...arr,
          {
            ...v,
            quantity: 1
          }
        ]
      }

      const tmpArr = [...arr]
      tmpArr[index].amount += 1
      tmpArr[index].price += v.price
      return tmpArr
    }, []),
    cashAmount: (state: any) => state.cashAmount || 0
  }

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
